<template>
  <v-btn
    :class="buttonClass"
    color="error"
    :loading="loading"
    @click="cancelExecution()"
  >
    Cancel Execution
  </v-btn>
</template>

<script>
import {
  showConfirmDialog,
  showSuccessDialog,
  showErrorDialog
} from '@/mixins/utils';

export default {
  name: 'CancelExecutionButton',
  components: {},
  props: {
    buttonClass: {
      type: String,
      default: 'ma-2'
    },
    executionId: {
      type: Number,
      required: true
    },
    executionType: {
      type: String,
      required: true
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    cancelExecution() {
      const self = this;
      self.loading = true;
      const isMyJobs = location.pathname === '/jobs';
      const isJPMessage =
        this.executionType === 'project_design' && !isMyJobs;
      const executionDisplayName =
        isMyJobs
          ? this.name
          : this.executionId;
      showConfirmDialog({
        title: isJPMessage
          ? '実行を中止します。'
          : `${executionDisplayName} will be canceled.`,
        html: isJPMessage
          ? 'この操作は取り消せません。<br><br> 本当に中止しますか?'
          : 'This operation cannot be undone. Are you sure you want to cancel it?',
        approveCB: () => {
          self.api.cancelExecution(
            self.executionId,
            self.executionType,
            function(data) {
              showSuccessDialog('',
                isJPMessage
                  ? '実行は中止されました。'
                  : isMyJobs
                    ? `${executionDisplayName} was canceled`
                    : `Execution ${executionDisplayName} was canceled.`
              );
              self.loading = false;
              self.$emit('refresh');
            },
            function(e) {
              showErrorDialog(
                isJPMessage
                  ? '予想外のエラーが発生しました。'
                  : 'An unexpected error occurred',
                isJPMessage
                  ? 'もう一度実行するか、解決しない場合は管理者に知らせてください。'
                  : 'Try again or let an administrator know if the problem persists.'
              );
              self.loading = false;
            }
          );
        },
        deniedCB: () => {
          self.loading = false;
        }
      });
    }
  }
};
</script>
