import api from '@/api';
import { showErrorDialog, showWarningDialog } from '@/mixins/utils';

export const linkablePredUncertModels = async(forUncertainty = false) => {
  let linkableModules = [];
  let types = ['SingleFeaturePrediction', 'MultipleFeaturePrediction'];
  if (!forUncertainty) {
    types = types.concat(['EDLUncertaintyModel', 'SOODUncertaintyModel']);
  }
  await api.getMLModules(
    function(modules) {
      linkableModules = modules;
    },
    function(error) {
      console.log(error);
    },
    {
      type: types.join(','),
      linkable: true,
      for_uncertainty: forUncertainty
    }
  );
  return linkableModules;
};

export const linkableObjectiveServers = async() => {
  let linkableModules = [];
  await api.getMLModules(
    function(modules) {
      linkableModules = modules;
    },
    function(error) {
      console.log(error);
    },
    {
      type: 'ObjectiveServer',
      linkable: true
    }
  );
  return linkableModules;
};

export const modelLinkableModules = async(id) => {
  let linkableModules = [];
  await api.getModelLinkableModules(
    function(modules) {
      linkableModules = modules;
    },
    function(error) {
      console.log(error);
    },
    id
  );
  return linkableModules;
};

export const modelLinkableOs = async(id) => {
  let linkableOs = [];
  await api.getModelLinkableOs(
    function(modules) {
      linkableOs = modules;
    },
    function(error) {
      console.log(error);
    },
    id
  );
  return linkableOs;
};

export const downloadWorkFolder = async(id, executionType) => {
  await api.downloadExecutionWorkingFolder(id, executionType,
    function() {},
    function(error) {
      if (error.status === 404) {
        showErrorDialog(
          'Error',
          'Work folder not found.'
        );
      } else {
        showErrorDialog(
          'Error',
          'Something went wrong. Contact an administrator.'
        );
      }
    });
};

export const notifyIfJobQueueFull = (approveCB) => {
  api.getProcessExecutionStatus(
    (executions) => {
      const { working, maximum, waiting } = executions;
      const totalJobs = working + waiting;

      if (totalJobs >= maximum) {
        showWarningDialog(
          'No available job slot',
          'There is actually no available job slot, so it may take a while before the execution is started.',
          approveCB
        );
      } else {
        approveCB();
      }
    },
    () => {
      approveCB();
    }
  );
};
