<template>
  <v-form ref="form" @submit.prevent>
    <v-card>
      <v-card-title>General information</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1 ">
              Type of the AI model
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="mlModule.type"
              :items="mlmTypes"
              label="Type"
              item-value="value"
              item-text="label"
              :rules="[v => !!v || 'Type is required']"
              required
              :disabled="readonly || (isVersionUp && !!parentModule.type)"
              @change="changeType"
            />
          </v-col>
          <v-col cols="1" />
          <v-col cols="2">
            <template v-if="!isUpdate && isFeaturePredictionType()">
              <div>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      :loading="isSelecting"
                      v-bind="attrs"
                      v-on="on"
                      @click="handleFileImport"
                    >
                      Import Information
                    </v-btn>
                  </template>
                  <span>
                    CSVファイルをインポートすることで、各項目を自動的に入力できる。
                  </span>
                </v-tooltip>
                <input
                  ref="uploader"
                  class="d-none"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  @change="onExcelImport"
                >
              </div>
            </template>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Name of the AI model
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.trim="mlModule.name"
              label="Name of the AI model"
              :rules="[
                v => (v || '').length <= 128 || 'Must be 128 characters or less',
                v => !!v || 'Name is required'
              ]"
              required
              :disabled="readonly || (isVersionUp && !!parentModule.name)"
            />
          </v-col>
          <v-col cols="1" />
          <template v-if="isMultiplePredictionType()">
            <v-col cols="2">
              <v-subheader class="pt-3 mt-1">
                Number of Task
              </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="mlModule.number_of_tasks"
                :rules="[
                  v => String(v).match(/^([1-9][0-9]*)$/) !== null || 'Value should be an integer greater than or equal to 1',
                  v => !!v || 'This field is required'
                ]"
                required
                placeholder="e.g. 40"
                :disabled="readonly"
              />
            </v-col>
          </template>
        </v-row>
        <v-row v-if="isStructureGeneration()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Number of extension points allowed
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  伸長点を1つだけ使用する場合は、「Exactly one」を選択する。<br>
                  複数の伸長点を使用する場合は、「Any number」を選択する。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="4">
            <v-radio-group
              v-model="mlModule.number_of_ext_pts"
              row
              :disabled="readonly || isVersionUp"
              @change="onChangeAllowedExtPtsSelection"
            >
              <v-radio
                v-for="option in extPtsOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
              />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="isStructureGeneration()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Reward settings
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  リワードを使うモデルを登録する場合、Use rewardをチェックし、リワード設定ファイルの名前を入力する。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="useReward"
              label="Use reward"
              :disabled="readonly || isVersionUp"
              @change="onUseReward"
            />
          </v-col>
          <v-col v-if="useReward" cols="4">
            <v-text-field
              v-model="mlModule.reward_config_file"
              label="Name of the reward config file"
              :rules="[
                v => !!v || 'This field is required'
              ]"
              placeholder="e.g. reward_config.yml"
              validate-on-blur
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="isStructureGeneration()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              RNN Settings
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  RNNを使用する場合はコンテナの中に、サーバー上にある RNN フォルダーをマウントする必要がある。<br>
                  Use RNNをチェックし、コンテナ内のマウント先を記述する。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="useRnn"
              label="Use RNN"
              :disabled="readonly || isVersionUp"
              @change="onUseRnn"
            />
          </v-col>
          <v-col v-if="useRnn" cols="4">
            <v-text-field
              v-model="mlModule.rnn_volume"
              label="RNN volume folder inside the container"
              :rules="[
                v => !!v || 'This field is required'
              ]"
              placeholder="e.g. /rnn"
              validate-on-blur
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="isFeaturePredictionType()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              {{ featureDisplayLabel() }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  予測項目の表示名称。<br>
                  15文字以内
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="mlModule.feature_display"
              :rules="[
                v => (v || '').length <= 15 || 'Must be 15 characters or less',
                v => !!v || 'This field is required'
              ]"
              required
              placeholder="A2a, AChE, Cav1.2 (L-type), Off-targets, ..."
              :disabled="readonly || (isVersionUp && !!parentModule.feature_display)"
            />
          </v-col>
          <v-col cols="1" />
          <v-col cols="2">
            <v-subheader class="pt-3 mt-1">
              Unit
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  予測値の単位。<br>
                  25文字以内
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="mlModule.unit"
              :rules="[
                v => (v || '').length <= 25 || 'Must be 25 characters or less',
                v => !!v || 'Unit is required'
              ]"
              required
              placeholder="nM, pKi, Probability@10uM (0~1), ..."
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="isFeaturePredictionType()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Explanation of the prediction target
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="mlModule.prediction_target"
              :rules="[
                v => (v || '').length <= 255 || 'Must be 255 characters or less',
                v => !!v || 'Prediction target is required'
              ]"
              required
              placeholder="e.g. A2a adenosine receptor inhibitory activity pIC50"
              :disabled="readonly"
            />
          </v-col>
          <v-col cols="1" />
          <v-col cols="2">
            <v-subheader class="pt-3 mt-1">
              Category
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-select
              v-model="mlModule.category"
              :items="mlmCategories"
              label="Category"
              item-value="value"
              item-text="label"
              :rules="[v => !!v || 'Category is required']"
              required
              :disabled="readonly || (isVersionUp && !!parentModule.category)"
            />
          </v-col>
        </v-row>
        <v-row v-if="isFeaturePredictionType()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Explanatory variable
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="mlModule.explanatory_variable"
              :rules="[
                v => (v || '').length <= 50 || 'Must be 50 characters or less',
                v => !!v || 'Explanatory variable is required'
              ]"
              required
              placeholder="e.g. Morgan FP (r=2)"
              :disabled="readonly"
            />
          </v-col>
          <v-col cols="1" />
          <v-col cols="2">
            <v-subheader class="pt-3 mt-1">
              Prediction algorithm
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="mlModule.prediction_algorithm"
              :rules="[
                v => (v || '').length <= 255 || 'Must be 255 characters or less',
                v => !!v || 'Prediction algorithm is required'
              ]"
              required
              placeholder="e.g. Support vector regression"
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="isFeaturePredictionType()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Benchmark
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="mlModule.benchmark"
              :rules="[
                v => (v || '').length <= 255 || 'Must be 255 characters or less',
                v => !!v || 'Benchmark is required'
              ]"
              required
              placeholder="e.g. Prediction performance for fold-out test data"
              :disabled="readonly"
            />
          </v-col>
          <v-col cols="1" />
          <v-col cols="2">
            <v-subheader class="pt-3 mt-1">
              {{ isSinglePredictionType() && !isRegression
                ? 'Data Size' : 'Mean data size' }}
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  学習データ数。<br>
                  1以上の整数
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model.number="mlModule.size_of_training_data"
              :rules="[
                v => !!v || 'This field is required',
                v => Number.isInteger(Number(v)) && v >= 1
                  || 'Must be an integer greater than or equal to 1',
              ]"
              required
              placeholder="e.g. 5000"
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="isFeaturePredictionType()" no-gutters>
          <template v-if="isRegression">
            <v-col cols="3">
              <v-subheader class="pt-3 mt-1">
                Predictive performance (Mean R²)
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <sup>
                      <v-icon
                        dense
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </sup>
                  </template>
                  <span>
                    予測性能 (R²)<br>
                    0~1の値
                  </span>
                </v-tooltip>
              </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="mlModule.r_squared"
                :rules="[
                  v => v != null && v.length !== 0 || 'This field is required',
                  v => 0 <= v && v <= 1 || 'Value should be 0 ~ 1'
                ]"
                required
                placeholder="e.g. 0.7"
                :disabled="readonly"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="3">
              <v-subheader class="pt-3 mt-1">
                {{ isSinglePredictionType() ? 'ROC Score' : 'Mean ROC Score' }}
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <sup>
                      <v-icon
                        dense
                        small
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-help-circle-outline
                      </v-icon>
                    </sup>
                  </template>
                  <span>
                    ROC Score<br>
                    0~1の値
                  </span>
                </v-tooltip>
              </v-subheader>
            </v-col>
            <v-col cols="3">
              <v-text-field
                v-model="mlModule.roc_score"
                :rules="[
                  v => v != null && v.length !== 0 || 'This field is required',
                  v => 0 <= v && v <= 1 || 'Value should be 0 ~ 1'
                ]"
                required
                placeholder="e.g. 0.7"
                :disabled="readonly"
              />
            </v-col>
          </template>
          <v-col cols="1" />
          <v-col cols="5">
            <v-radio-group
              v-model="isRegression"
              :disabled="readonly"
              row
            >
              <v-radio label="回帰分析" :value="true" />
              <v-radio label="判別分析" :value="false" />
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="isLinkableModuleType()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1 ">
              {{ linkageLabel }}
              <v-tooltip v-if="linkageHelp !== ''" top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span v-html="linkageHelp" />
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              ref="linkableModuleSelector"
              v-model="mlModule.moduleToLink"
              :items="linkableModules"
              :label="linkageLabel"
              :rules="[v => !isUncertaintyType() || !!v || 'This field is required']"
              item-value="id"
              item-text="name"
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="isUncertaintyType()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1 ">
              Link to an existing objective server
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>リストの中から登録済みのObjective serverを選択する</span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="3">
            <v-autocomplete
              ref="linkableModuleSelector"
              v-model="mlModule.osForUncertainty"
              :items="linkableOsForUncertainty"
              label="Link to an existing objective server"
              item-value="id"
              item-text="name"
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Description of the AI model
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-textarea
              v-model="mlModule.description"
              label="Description"
              :rules="[
                v => !!v || 'Description is required',
                v => v.length <= 200 || 'Description can only contain up to 200 characters',
                v => ( v.match( /\n/g ) || [] ).length < 10 || 'Description can only contain up to 10 lines'
              ]"
              auto-grow
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Additional documentation
            </v-subheader>
          </v-col>
          <v-col cols="9" align-self="center" class="pt-3 mt-1">
            <span
              v-if="readonly && parentModule.document_name"
              class="downloadLink"
              @click="downloadDocument"
            >
              {{ parentModule.document_name }}
            </span>
            <v-file-input
              v-else-if="!readonly"
              v-model="document"
              label="Additional documentation"
              show-size
              :rules="documentRules"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mt-5 mb-5">
      <v-card-title>Environment and script information</v-card-title>
      <v-card-text>
        <v-row v-if="!readonly" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Import configuration from a JSON file
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  登録済みのモデルと同様な設定を使う場合、下記の設定をJSONファイルから簡便に入力できる。<br>
                  JSONファイルはMy AI modelsの
                  <v-icon color="white">mdi-file-download-outline</v-icon>
                  からダウンロードできる。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-file-input
              v-model="config"
              label="Configuration file (JSON format)"
              accept=".json"
              @change="readFile"
            />
          </v-col>
        </v-row>
        <v-divider v-if="!readonly" />

        <v-row no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Image environment
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="mlModule.environment"
              :items="environments"
              :rules="[v => !!v || 'The image environment is required']"
              required
              :disabled="isUpdate || readonly"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pl-4" cols="3">
            <v-subheader class="pt-3 mt-1">
              Image providing method
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-select
              v-model="imageProvideMethod"
              :items="imageProvideMethods"
              required
              :disabled="isUpdate || readonly"
              @change="fetchSameTypeModels"
            />
          </v-col>
        </v-row>
        <v-row v-if="imageProvideMethod === 'box'" no-gutters>
          <v-col class="pl-8" cols="3">
            <v-subheader class="pt-3 mt-1">
              Path to the image file (Box)
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  BoxのTest共有フォルダ内のイメージファイルのパス。<br>
                  "Test"をパスに含めて書かない。<br>
                  許可されている拡張子は.tar, .tgz, .gz, .bz2である。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="mlModule.path"
              placeholder="e.g. /Folder1/Tox Prediction/model_v3.tar.gz"
              :rules="pathRules"
              validate-on-blur
              required
              :disabled="isUpdate || readonly"
              @blur="onPathBlur"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="imageProvideMethod === 'server'" no-gutters>
          <v-col class="pl-8" cols="3">
            <v-subheader class="pt-3 mt-1">
              Docker image filename
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  <!-- TODO: Write help text in japanese -->
                  Dockerイメージフォルダ内のファイルパスを入力する。<br>
                  イメージフォルダからではなく、その下からのパス。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="mlModule.path"
              placeholder="e.g. model_v3.tar.gz"
              :rules="pathRules"
              validate-on-blur
              required
              :disabled="isUpdate || readonly"
              @blur="onPathBlur"
            />
          </v-col>
        </v-row>
        <v-row v-else-if="imageProvideMethod === 'container'" no-gutters>
          <v-col class="pl-8" cols="3">
            <v-subheader class="pt-3 mt-1">
              Use the same image as
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  登録済みのモデルで使われているDockerイメージを利用する。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-autocomplete
              v-model="selectedReferenceModel"
              :items="selectableModels"
              :rules="[v => !!v || 'The reference model is required']"
              required
              :disabled="isUpdate || readonly"
              :no-data-text="mlModule.type
                ? 'No data available.'
                : 'Please first select the type of the AI model.'"
              @change="onReferenceModelChange(selectedReferenceModel)"
            />
          </v-col>
        </v-row>
        <v-row align="center" no-gutters>
          <v-col class="pl-4" cols="3">
            <v-subheader class="pt-3 mt-1">
              Requires an external license
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  外部ライセンスが必要の場合、チェックする。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="1" class="pt-3">
            <v-checkbox
              v-model="requiresLicense"
              :disabled="readonly || isVersionUp"
              @change="changeRequiresLicense"
            />
          </v-col>
          <v-col v-if="requiresLicense" cols="7">
            <v-row no-gutters>
              <v-col class="pl-4" cols="3">
                <v-subheader class="pt-3 mt-1">
                  Path to the license directory on the server
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <sup>
                        <v-icon
                          dense
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </sup>
                    </template>
                    <span>
                      管理者から受け取ったパスを記入する。
                    </span>
                  </v-tooltip>
                </v-subheader>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="mlModule.license_dir_server"
                  placeholder="e.g. /opt/licensed_tool"
                  :rules="[
                    v => !!v || 'The path on the server is required'
                  ]"
                  required
                  :disabled="readonly"
                />
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col class="pl-4" cols="3">
                <v-subheader class="pt-3 mt-1">
                  Path to the license directory inside the container
                  <v-tooltip top>
                    <template #activator="{ on, attrs }">
                      <sup>
                        <v-icon
                          dense
                          small
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-help-circle-outline
                        </v-icon>
                      </sup>
                    </template>
                    <span>
                      コンテナ側のライセンスのフォルダのパス。<br>
                      サーバー側のフォルダはこのパスにマウントされる。
                    </span>
                  </v-tooltip>
                </v-subheader>
              </v-col>
              <v-col cols="9">
                <v-text-field
                  v-model="mlModule.license_dir_container"
                  placeholder="e.g. /work/my_license"
                  :rules="[
                    v => !!v || 'The path inside the container is required'
                  ]"
                  required
                  :disabled="readonly"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="!isObjectiveServer()" no-gutters>
          <v-col class="pl-4" cols="3">
            <v-subheader class="pt-3 mt-1">
              Path to the mounted directory
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  コンテナ側から見たマウントされたフォルダのパス。<br>
                  実行前に、入力ファイルをこのフォルダに置くことで読み込むことができる。<br>
                  実行後、このフォルダにステータスファイルと出力ファイルを置かなければならない。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="mlModule.volume"
              placeholder="e.g. /work"
              :rules="[
                v => !!v || 'The path to the mounted directory is required'
              ]"
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col class="pl-4" cols="3">
            <v-subheader class="pt-3 mt-1">
              <div>
                {{ commandLabel }}
                <div v-if="useReward" class="red-text">
                  *Use ${reward_config} to set the reward settings file
                  in the command.<br>
                </div>
              </div>
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span v-html="commandHelp" />
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="mlModule.command"
              :placeholder="commandPlaceholder"
              :rules="commandRules"
              validate-on-blur
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row
          v-if="isIDAssignableModuleType()"
          no-gutters
        >
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              ID assignment
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  1. 入出力に紐づける化合物IDとして、任意の値を指定可能。<br>
                  2. 入出力に紐づける化合物IDとして、モデル側が指定する名前が必要。<br>
                  3. 入出力に化合物IDを紐づけせず、計算結果のみを入力順に出力する。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-radio-group
              v-model="mlModule.module_id_policy.id_policy"
              column
              :rules="[v => !!v || 'One option must be selected']"
              :disabled="readonly"
              @change="onChangeModelIdPolicy"
            >
              <div
                v-for="(option, index) in modelIdPolicyOptions"
                :key="index"
                class="inline-radio-text-field"
                :style="{ 'margin-top': option.value === 'Fixed' ? '7px' : '0' }"
              >
                <v-radio
                  :label="option.label"
                  :value="option.value"
                />
                <v-text-field
                  v-if="option.value === 'Fixed'"
                  v-model="mlModule.module_id_policy.fixed_id_name"
                  placeholder="e.g. Name"
                  label="Column name"
                  :rules="[
                    v => !(mlModule.module_id_policy.id_policy === 'Fixed' && !v)
                      || 'This input field is required'
                  ]"
                  :disabled="readonly
                    || mlModule.module_id_policy.id_policy !== 'Fixed'"
                  class="id-name"
                  dense
                />
              </div>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="isObjectiveServer()" class="mb-4" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Objective values
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-dialog
              v-model="objValDialog"
              width="500"
            >
              <template #activator="{ on, attrs }">
                <v-subheader class="pt-3 mt-1">
                  <span class="mr-2">
                    {{ mlModule.objective_values.length }} objective values
                  </span>
                  <v-btn
                    color="red lighten-2"
                    dark
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ readonly ? 'View' : 'Update' }} objective values
                  </v-btn>
                </v-subheader>
              </template>

              <ObjectiveValueList
                :curr-values="mlModule.objective_values"
                :readonly="readonly"
                @update="updateObjVal"
                @close="objValDialog=false"
              />
            </v-dialog>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Argument(s)
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  必須項目は選択したAIの種類ごとに異なる。<br>
                  必須項目は自動的に表示され名前と型は変更できない。<br>
                  必須項目はデフォルト値を指定しなければならない。<br>
                  上記以外の項目を追加する場合は+ボタンを押す。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>

          <RegistrationArgumentList
            ref="arguments"
            :inputs="inputs"
            :input-types="inputTypes"
            :readonly="readonly"
            preserve-mandatory-fields
            @addInput="addInput"
            @changeInputType="changeInputType"
            @addParameter="addParameter"
            @addElement="addElement"
            @removeLastInput="removeLastInput"
            @collapseExpand="collapseExpand"
          />
        </v-row>
        <v-row v-if="isObjectiveServer()" no-gutters>
          <v-col class="pl-4" cols="3">
            <v-subheader class="pt-3 mt-1">
              Path to the mounted directory
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  コンテナ側から見たマウントされたフォルダのパス。<br>
                  実行前に、入力ファイルをこのフォルダに置くことで読み込むことができる。<br>
                  実行後、このフォルダにステータスファイルと出力ファイルを置かなければならない。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="mlModule.volume"
              placeholder="e.g. /work"
              :rules="[
                v => !!v || 'The path to the mounted directory is required'
              ]"
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
        <v-row v-if="!isObjectiveServer()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Name of the output file(s)
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  ユーザーがダウンロードできるファイルを一つずつ入力する。<br>
                  最初のファイルが結果として表示されるファイルである。<br>
                  実行後、出力ファイルはマウントされたフォルダに置かなければならない。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-for="(output, index) in outputs"
              :key="index"
              v-model="output.value"
              placeholder="e.g. output_compounds.csv"
              :rules="[v => validateOutputFilename(v, index)]"
              validate-on-blur
              required
              :disabled="readonly"
            />
            <span v-if="!readonly">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    :disabled="!canAddOutput"
                    color="blue"
                    dense
                    v-bind="attrs"
                    @click="addOutput"
                    v-on="on"
                  >
                    mdi-plus-circle
                  </v-icon>
                </template>
                <span>Add one more output</span>
              </v-tooltip>
            </span>
            <span v-if="!readonly">
              <v-tooltip v-if="canRemoveOutput" top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    color="red"
                    dense
                    v-bind="attrs"
                    @click="removeLastOutput"
                    v-on="on"
                  >
                    mdi-minus-circle
                  </v-icon>
                </template>
                <span>Remove last output</span>
              </v-tooltip>
            </span>
          </v-col>
        </v-row>
        <v-row v-if="!isObjectiveServer()" no-gutters>
          <v-col cols="3">
            <v-subheader class="pt-3 mt-1">
              Name of the status file
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <sup>
                    <v-icon
                      dense
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-help-circle-outline
                    </v-icon>
                  </sup>
                </template>
                <span>
                  実行ステータスをシステムが確認するためのファイル。<br>
                  実行ステータスを本ファイルに書き込み、<br>
                  実行が完了したときのステータスはCOMPLETEと書かなければならない。<br>
                  ステータスファイルはマウントされたフォルダに置かなければならない。
                </span>
              </v-tooltip>
            </v-subheader>
          </v-col>
          <v-col cols="9">
            <v-text-field
              v-model="mlModule.status"
              placeholder="e.g. status.txt"
              :rules="[v => !!v || 'The name of the status file is required']"
              required
              :disabled="readonly"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-form>
</template>

<script>
import RegistrationArgumentList from '@/components/RegistrationArgumentList';
import ObjectiveValueList from '@/components/ObjectiveValueList';
import consts from '@/store/consts';
import { showConfirmDialog, showErrorDialog } from '@/mixins/utils';
import {
  linkablePredUncertModels,
  linkableObjectiveServers,
  modelLinkableModules,
  modelLinkableOs
} from '@/mixins/api_utils';
import readXlsxFile from 'read-excel-file';

export default {
  name: 'ModelRegistrationForm',
  components: {
    RegistrationArgumentList: RegistrationArgumentList,
    ObjectiveValueList: ObjectiveValueList
  },
  props: {
    parentModule: {
      type: Object,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isUpdate: {
      type: Boolean,
      default: false
    },
    isVersionUp: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mlModule: {
        name: '',
        type: '',
        feature_display: '',
        unit: '',
        description: '',
        moduleToLink: null,
        environment: 'docker',
        path: '',
        volume: '',
        status: 'status.txt',
        command: '',
        input: {},
        // Should be ouputs, but for ease of use, it's singular
        output: [],
        license_dir_server: '',
        license_dir_container: '',
        category: '',
        prediction_target: '',
        explanatory_variable: '',
        prediction_algorithm: '',
        size_of_training_data: '',
        benchmark: '',
        r_squared: null,
        roc_score: null,
        number_of_tasks: '',
        module_id_policy: {
          id_policy: null,
          fixed_id_name: ''
        },
        reward_config_file: '',
        rnn_volume: '',
        number_of_ext_pts: '',
        objective_values: [],
        osForUncertainty: null
      },
      objValDialog: false,
      inputs: [
        { 'name': '', 'type': '', 'description': '', 'disabled': false }
      ],
      useReward: false,
      useRnn: false,
      outputs: [{ 'value': '' }],
      environments: [
        { text: 'Docker', value: 'docker' }
      ],
      inputTypes: [
        { label: 'YAML', value: 'yaml' },
        { label: 'JSON', value: 'json' },
        { label: 'File', value: 'file' },
        { label: 'SMILES', value: 'smiles' },
        { label: 'Dictionary', value: 'dictionary' },
        { label: 'List of arguments', value: 'list' },
        { label: 'List of strings (comma-separated)', value: 'list-strings' },
        { label: 'List of numbers (comma-separated)', value: 'list-numbers' },
        { label: 'List of files ', value: 'list-files' },
        { label: 'Select', value: 'select' },
        { label: 'Boolean', value: 'boolean' },
        { label: 'Float', value: 'float' },
        { label: 'Integer', value: 'integer' },
        { label: 'String', value: 'string' }
      ],
      config: null,
      document: null,
      mlmTypes: [],
      mlmCategories: [],
      compoundsFile: null,
      predictedFile: null,
      // Validation
      pathRules: [
        v => !!v || 'The path to the image is required',
        v => (v && (v.endsWith('.tar') ||
                    v.endsWith('.tgz') ||
                    v.endsWith('.gz') ||
                    v.endsWith('.bz2'))
        ) || 'This file extension is not supported'
      ],
      commandRules: [
        v => !!v || 'The command to execute is required',
        v => (v && !v.startsWith('docker')) ||
                  'Please write the command to be called INSIDE the container',
        v => (v && v.match(/\$([^{]|{\w+([^}\w]|$))/) === null) ||
                  // eslint-disable-next-line no-template-curly-in-string
                  'Please use $ with braces: ${...}'
      ],
      documentRules: [
        _ => (this.document === null || this.document.size > 0) || 'File is empty'
      ],
      imageProvideMethods: [
        { text: 'Box upload', value: 'box' },
        { text: 'File on server', value: 'server' },
        { text: 'Same image as another model', value: 'container' }
      ],
      imageProvideMethod: 'box',
      selectableModels: [],
      selectedReferenceModel: '',
      requiresLicense: false,
      maxTrainingDataSize: 2147483647, // max IntagerField
      isSelecting: false,
      isRegression: true,
      info_file: null,
      allObjectiveServers: [],
      allPredictionModels: [],
      allPredictionModelsForUncertainty: [],
      linkableModules: [],
      linkableOsForUncertainty: [],
      modelLinkableModules: [],
      rewards: [],
      modelIdPolicyOptions: [],
      tempIdInputField: null,
      extPtsOptions: []
    };
  },
  computed: {
    canRemoveOutput: function() {
      return this.outputs.length > 1;
    },
    canAddOutput: function() {
      return this.outputs[this.outputs.length - 1].value !== '';
    },
    linkageLabel: function() {
      return this.isObjectiveServer() || this.isUncertaintyType()
        ? 'Link to an existing prediction model'
        : 'Link to an existing objective server';
    },
    linkageHelp: function() {
      return (this.isUncertaintyType()
        ? 'リンクモデルのUncertainty予測に使用。'
        : '構造発生時のReward設定で利用される。') + '<br>' +
      (this.isObjectiveServer() || this.isUncertaintyType()
        ? 'リストの中から登録済みの予測モデルを選択する。'
        : 'リストの中から登録済みのObjective serverを選択する。');
    },
    commandLabel: function() {
      return this.mlModule.type === 'ObjectiveServer'
        ? 'Command to execute to start container'
        : 'Command to execute inside environment';
    },
    commandHelp: function() {
      return this.mlModule.type === 'ObjectiveServer'
        ? 'docker runやrepository:tagなどを書かずに、コンテナないでObjective serverを起動するためのコマンド。'
        : `コンテナ内で実行されるコマンド。<br>
          実行結果が上記で指定されたフォルダに出力されるように記載する。<br>
          引数は\${argument_name}の形で記載する。<br>
          argument_nameは下記のArgumentsで指定するArgument nameを使用する。`;
    },
    commandPlaceholder: function() {
      return this.mlModule.type === 'ObjectiveServer'
        ? 'e.g. python objective_server.py'
        : 'e.g. sh /run.sh \${target_model} \${compounds_file} \${compound_identifier_name} \${compound_structure_name} \${predicted_value_name}';
    }
  },
  watch: {
    outputs: {
      handler: function() {
        this.$emit('outputChanged', this.outputs);
      },
      deep: true
    },
    parentModule: {
      handler: async function() {
        await this.fillFormFromParentModule();
      },
      deep: true
    },
    imageProvideMethod: {
      handler: function(oldValue, newValue) {
        if (oldValue === 'container') {
          this.selectedReferenceModel = '';
        }
        this.mlModule.path = this.parentModule?.path || '';
      }
    },
    isRegression: {
      handler: function() {
        if (this.isRegression) {
          this.mlModule.roc_score = null;
        } else {
          this.mlModule.r_squared = null;
        }
      }
    },
    mlmTypes: {
      handler: async function() {
        if (this.mlModule.type === '' && this.parentModule && this.parentModule.category !== '') {
          await this.fillFormFromParentModule();
        }
      }
    },
    mlmCategories: {
      handler: async function() {
        if (this.mlModule.category === '' && this.mlModule.type && this.parentModule.category !== '') {
          await this.fillFormFromParentModule();
        }
      }
    }
  },
  async mounted() {
    const self = this;
    await this.api.getMLMTypes(function(list) {
      self.mlmTypes = list;
    });
    await this.api.getMLMCategories(function(list) {
      self.mlmCategories = list;
    });
    await this.api.getRewards(function(list) {
      self.rewards = list;
    });
    await this.api.getModelIdPolicyOptions(function(list) {
      self.modelIdPolicyOptions = list;
    });
    await this.api.getAllowedExtPtsOption(function(list) {
      self.extPtsOptions = list;
    });
  },
  methods: {
    addInput() {
      this.inputs.push(
        { 'name': '', 'type': '', 'description': '', 'default': '' });
      this.showSimpleTest();
    },
    removeLastInput(inputs) {
      inputs.pop();
      this.showSimpleTest();
    },
    changeInputType(index, targetInput) {
      delete targetInput.parameters;
      delete targetInput.element;
      if (this.noDefaultInput(targetInput.type)) {
        delete targetInput.default;
      }
      if (['yaml', 'json'].includes(targetInput.type)) {
        // Use this.$set to make it reactive
        this.$set(targetInput, 'parameters', [
          { 'name': '', 'type': '', 'description': '', 'filename': '' }
        ]);
      } else if (['list', 'dictionary'].includes(targetInput.type)) {
        // Use this.$set to make it reactive
        this.$set(targetInput, 'element', [
          { 'name': '', 'type': '', 'description': '' }
        ]);
      }
    },
    addParameter(index, targetInput) {
      const lastParam = targetInput.parameters.slice(-1);
      if (lastParam.name !== '' &&
                lastParam.type !== '' &&
                lastParam.description !== '') {
        targetInput.parameters.push(
          { 'name': '', 'type': '', 'description': '', 'default': '' });
      }
    },
    addElement(index, targetInput) {
      const lastElement = targetInput.element[targetInput.element.length - 1];
      if (lastElement.name !== '' &&
                lastElement.type !== '' &&
                lastElement.description !== '') {
        targetInput.element.push(
          { 'name': '', 'type': '', 'description': '', 'default': '' });
      }
    },
    addOutput() {
      this.outputs.push({ 'value': '' });
    },
    removeLastOutput() {
      this.outputs.pop();
    },
    noDefaultInput(type) {
      return [
        'yaml', 'json', 'file', 'dictionary', 'list', 'list-files'
      ].includes(type);
    },
    generateMandatoryFields(type) {
      // Copy values to avoid changing them
      const fields = [
        { ...consts.MandatoryFields.cmpStrucName }
      ];
      if (this.mlModule.module_id_policy.id_policy === 'ByArgument') {
        fields.unshift({ ...consts.MandatoryFields.cmpIdName });
      }
      if (this.isPredictionOrUncertaintyType(type) ||
          this.isStructureFilter(type)) {
        fields.unshift({ ...consts.MandatoryFields.cmpFile });
      }
      if (type === 'SingleFeaturePrediction') {
        fields.push({ ...consts.MandatoryFields.predValName });
      }
      if (type === 'StructureFilter') {
        fields.push({ ...consts.MandatoryFields.predFilValName });
      }
      if (type === 'StructureGeneration' &&
        this.mlModule.number_of_ext_pts === 'ExactlyOne') {
        fields.push({ ...consts.MandatoryFields.inputSmiles });
      }
      if (type === 'ObjectiveServer') {
        return [];
      }
      return fields;
    },
    async changeType(type) {
      if (!this.isUncertaintyType(type) && this.$refs.linkableModuleSelector) {
        this.$refs.linkableModuleSelector.resetValidation();
      }
      if (this.isSinglePredictionType() || this.isUncertaintyType()) {
        this.mlModule.number_of_tasks = '';
      }
      if (this.isStructureGeneration(type)) {
        this.mlModule.number_of_ext_pts = 'ExactlyOne';
      } else {
        this.mlModule.number_of_ext_pts = '';
      }
      if (!this.isIDAssignableModuleType()) {
        this.mlModule.module_id_policy = {
          id_policy: null,
          fixed_id_name: ''
        };
      }
      this.inputs = this.generateMandatoryFields(type);
      this.showSimpleTest();

      this.selectableModels = [];
      this.selectedReferenceModel = '';
      this.fetchSameTypeModels();

      await this.setLinkableModules();
      this.mlModule.moduleToLink = null;
      this.mlModule.osForUncertainty = null;

      if (this.isObjectiveServer()) {
        this.outputs = [{ value: '' }];
      }

      const newModelType = this.mlmTypes.find(d => d.value === type).label;
      this.$emit('typeChanged', newModelType);
    },
    collapseExpand(targetInput) {
      // Use this.$set to make it reactive
      this.$set(targetInput, 'collapsed', !targetInput.collapsed);
    },
    readFile(file) {
      const reader = new FileReader();
      reader.onload = e => {
        this.jsonConfig = JSON.parse(e.target.result);
        this.fillFormFromJSON(this.jsonConfig);
      };
      reader.readAsText(file);
    },
    fillFormFromJSON(jsonConfig) {
      this.mlModule.command = jsonConfig.command;
      this.mlModule.status = jsonConfig.status;
      this.mlModule.volume = jsonConfig.volume;
      this.mlModule.objective_values = jsonConfig.objective_values || [];
      if (jsonConfig.module_id_policy) {
        const policy = jsonConfig.module_id_policy;
        this.mlModule.module_id_policy.id_policy = policy.id_policy;
        this.mlModule.module_id_policy.fixed_id_name = policy.id_policy === 'Fixed'
          ? policy.fixed_id_name
          : '';
      }
      this.outputs = [];
      if (Array.isArray(jsonConfig.output)) {
        // Objective server does not have output
        for (const output of jsonConfig.output) {
          this.outputs.push({ 'value': output });
        }
      }
      this.inputs = this.generateMandatoryFields(this.mlModule.type);
      for (const [input, details] of Object.entries(jsonConfig.input || {})) {
        const { parameters, element, ...neededArgs } = details;
        const createdInput = neededArgs;
        createdInput.name = input;

        if ('parameters' in details) {
          this.fillParameter(createdInput, details);
        } else if ('element' in details) {
          this.fillElement(createdInput, details);
        }
        const index = this.inputs.findIndex(i => i.name === createdInput.name);
        if (index > -1) {
          this.$set(this.inputs, index, createdInput);
        } else {
          this.inputs.push(createdInput);
        }
      }
    },
    fillParameter(createdInput, paramDetails) {
      createdInput.parameters = [];
      for (const [param, pDetails] of Object.entries(paramDetails.parameters)) {
        const { parameters, element, ...neededParams } = pDetails;
        const createdParam = neededParams;
        createdParam.name = param;
        createdInput.parameters.push(createdParam);
        if ('parameters' in pDetails) {
          this.fillParameter(createdParam, pDetails);
        } else if ('element' in pDetails) {
          this.fillElement(createdParam, pDetails);
        }
      }
    },
    fillElement(createdInput, elemDetails) {
      createdInput.element = [];
      for (const [elem, eleDetails] of Object.entries(elemDetails.element)) {
        const { parameters, element, ...neededElems } = eleDetails;
        const createdElem = neededElems;
        createdElem.name = elem;
        createdInput.element.push(createdElem);
        if ('parameters' in eleDetails) {
          this.fillParameter(createdElem, eleDetails);
        } else if ('element' in eleDetails) {
          this.fillElement(createdElem, eleDetails);
        }
      }
    },
    fillLicensePaths(module) {
      if (module.license_dir_server && module.license_dir_container) {
        this.requiresLicense = true;
        this.mlModule.license_dir_server = module.license_dir_server;
        this.mlModule.license_dir_container = module.license_dir_container;
      }
    },
    async fillFormFromParentModule() {
      const self = this;
      await this.api.getMLMTypes(function(list) {
        self.mlmTypes = list;
      });
      this.mlModule.id = this.parentModule.id;
      this.mlModule.name = this.parentModule.name;
      const type = this.mlmTypes.filter(
        d => d.label === this.parentModule.type
      );
      this.mlModule.type = type.length > 0 ? type[0].value : '';
      if (this.isPredictionOrUncertaintyType()) {
        // Category may not be set for old models
        await this.api.getMLMCategories(function(list) {
          self.mlmCategories = list;
        });
        const cat = this.mlmCategories.filter(
          d => d.label === this.parentModule.category
        );
        this.mlModule.category = cat.length > 0 ? cat[0].value : '';
        this.mlModule.feature_display = this.parentModule.feature_display;
        this.mlModule.unit = this.parentModule.unit;
        this.mlModule.prediction_target = this.parentModule.prediction_target;
        this.mlModule.explanatory_variable = this.parentModule.explanatory_variable;
        this.mlModule.prediction_algorithm = this.parentModule.prediction_algorithm;
        this.mlModule.benchmark = this.parentModule.benchmark;
        this.mlModule.size_of_training_data = this.parentModule.size_of_training_data;
        this.mlModule.r_squared = this.parentModule.r_squared;
        this.mlModule.roc_score = this.parentModule.roc_score;
        this.mlModule.number_of_tasks = this.parentModule.number_of_tasks;
      }
      if (this.isUpdate) {
        this.mlModule.moduleToLink = this.parentModule.linked_mlm_id;
        if (this.isUncertaintyType(this.parentModule.type)) {
          this.mlModule.osForUncertainty = this.parentModule.uncertainty_linked_os_id;
          this.linkableOsForUncertainty = await modelLinkableOs(this.$route.params.id);
        }
        this.modelLinkableModules = await modelLinkableModules(this.$route.params.id);
      }
      this.mlModule.description = this.parentModule.description;
      this.imageProvideMethod = this.parentModule.method;
      this.mlModule.path = this.parentModule.path;
      this.isRegression = this.parentModule.r_squared !== null;
      if (this.parentModule.module_id_policy) {
        this.mlModule.module_id_policy = {
          ...this.parentModule.module_id_policy
        };
      }
      this.fillLicensePaths(this.parentModule);
      this.fillFormFromJSON(JSON.parse(this.parentModule.config));
      this.useReward = this.parentModule.reward_config_file !== '';
      this.useRnn = this.parentModule.rnn_volume !== '';
      this.mlModule.reward_config_file = this.parentModule.reward_config_file;
      this.mlModule.rnn_volume = this.parentModule.rnn_volume;
      this.mlModule.number_of_ext_pts = this.parentModule.number_of_ext_pts;
      await this.setLinkableModules();
    },
    async linkablePredUncertModels() {
      // Manual cache
      if (this.allPredictionModels.length === 0) {
        if (this.isUpdate && this.isObjectiveServer(this.parentModule.type)) {
          this.allPredictionModels = this.modelLinkableModules;
        } else {
          this.allPredictionModels = await linkablePredUncertModels();
        }
      }
      return this.allPredictionModels;
    },
    async linkableObjectiveServers() {
      // Manual cache
      if (this.allObjectiveServers.length === 0) {
        if (this.isUpdate &&
            this.isFeaturePredictionType(this.parentModule.type)) {
          this.allObjectiveServers = this.modelLinkableModules;
        } else {
          this.allObjectiveServers = await linkableObjectiveServers();
        }
      }
      return this.allObjectiveServers;
    },
    async linkablePredictionModelsForUncertainty() {
      // Manual cache
      if (this.allPredictionModelsForUncertainty.length === 0) {
        if (this.isUpdate && this.isUncertaintyType(this.parentModule.type)) {
          this.allPredictionModelsForUncertainty = this.modelLinkableModules;
        } else {
          this.allPredictionModelsForUncertainty = await linkablePredUncertModels(true);
        }
      }
      return this.allPredictionModelsForUncertainty;
    },
    async setLinkableModules() {
      if (this.isFeaturePredictionType()) {
        this.linkableModules = await this.linkableObjectiveServers();
      } else if (this.isObjectiveServer()) {
        this.linkableModules = await this.linkablePredUncertModels();
      } else if (this.isUncertaintyType()) {
        this.linkableModules = await this.linkablePredictionModelsForUncertainty();
        if (!this.isUpdate) {
          this.linkableOsForUncertainty = await this.linkableObjectiveServers();
        }
      }
    },
    convertInputOutput() {
      // Convert input/output to format required by API
      this.mlModule.output = this.outputs.map((o) => {
        return o.value;
      });
      for (const input of this.inputs) {
        const { name, parameters, element, collapsed, ...neededArgs } = input;
        this.mlModule.input[input.name] = neededArgs;

        if ('parameters' in input) {
          this.convertParameter(input, this.mlModule.input[input.name]);
        } else if ('element' in input) {
          this.convertElement(input, this.mlModule.input[input.name]);
        }
      }
    },
    convertParameter(input, mlModuleInput) {
      mlModuleInput.parameters = {};
      input.parameters.forEach(param => {
        const { name, parameters, element, collapsed, ...neededParams } = param;
        mlModuleInput.parameters[param.name] = neededParams;

        if ('parameters' in param) {
          this.convertParameter(param, mlModuleInput.parameters[param.name]);
        } else if ('element' in param) {
          this.convertElement(param, mlModuleInput.parameters[param.name]);
        }
      });
    },
    convertElement(input, mlModuleInput) {
      mlModuleInput.element = {};
      input.element.forEach(elem => {
        const { name, parameters, element, collapsed, ...neededElems } = elem;
        mlModuleInput.element[elem.name] = neededElems;

        if ('parameters' in elem) {
          this.convertParameter(elem, mlModuleInput.element[elem.name]);
        } else if ('element' in elem) {
          this.convertElement(elem, mlModuleInput.element[elem.name]);
        }
      });
    },
    expandAllInputs(inputs) {
      for (const input of inputs) {
        if (input.collapsed) {
          input.collapsed = false;
        }
        if (input.parameters) {
          this.expandAllInputs(input.parameters);
        } else if (input.element) {
          this.expandAllInputs(input.element);
        }
      }
    },
    validateForm() {
      // Add some validation rules that are only necessary before submission
      if (this.commandRules.length < 3) {
        this.commandRules.push(
          v => {
            const match = this.commandArgumentRule(v);
            return match ? `${match} is not in the list of arguments` : true;
          }
        );
      }
      if (this.isStructureGeneration()) {
        const inputSmilesCount = this.countInputSmilesField(this.inputs);
        if (inputSmilesCount[1] > 0) {
          showErrorDialog(
            'Invalid argument',
            'Only one argument named "input_smiles" can be registered. </br> ' +
            '"input_smiles" can not be a element of list type argument'
          );
          return;
        }
        if (inputSmilesCount[0] !== 1) {
          const errorMsg = inputSmilesCount[0] === 0
            ? 'One argument named "input_smiles" is required'
            : 'Only one argument named "input_smiles" can be registered';
          showErrorDialog(
            'Invalid argument',
            errorMsg
          );
          return;
        }
      }
      // Can not write 'this.$refs.arguments.validate()' inside 'if' condition
      // If this.$refs.form.validate() returns false it goes to the else block,
      // without execute the validate function for arguments refs
      const customArgValidation = this.$refs.arguments.validate();
      if (this.$refs.form.validate() && customArgValidation) {
        const files = {};
        if (this.document !== null) {
          files.document = this.document;
        }
        if (this.info_file !== null) {
          files.info_file = this.info_file;
        }
        this.convertInputOutput();
        this.mlModule = {
          ...this.mlModule,
          imageProvideMethod: this.imageProvideMethod,
          selectedReferenceModel: this.selectedReferenceModel
        };
        this.$emit('formValidated', this.mlModule, files);
      } else {
        this.expandAllInputs(this.inputs);
        this.$nextTick(() => {
          const el = document.querySelector('.error--text');
          // Following two lines is a twitching to determine the exact location
          // of the form element displaying the error message and has a height of 48 px
          // The `custom` class is added to a button working as a form element with height around 96
          const correction = el.classList.contains('custom') ? 96 : 48;
          const y = el.getBoundingClientRect().top + window.pageYOffset - correction;
          window.scrollTo({ 'top': y, 'behavior': 'smooth' });
        });
      }
    },
    showSimpleTest() {
      if (this.isPredictionOrUncertaintyType() &&
                this.inputs[this.inputs.length - 1].disabled) {
        this.$emit('showSimpleTest', true);
      } else {
        this.$emit('showSimpleTest', false);
      }
    },
    onPathBlur() {
      if (this.imageProvideMethod === 'box' &&
          !this.mlModule.path.startsWith('/')) {
        this.mlModule.path = '/' + this.mlModule.path;
      } else if (this.imageProvideMethod === 'server' &&
          this.mlModule.path.startsWith('/')) {
        this.mlModule.path = this.mlModule.path.substring(1);
      }
    },
    // Validation
    commandArgumentRule(value) {
      const regExp = /\${([^}]+)\}/g;
      let match = regExp.exec(value);
      if (match) {
        do {
          if (this.inputs.every(i => i.name !== match[1])) {
            return match[1];
          }
        } while ((match = regExp.exec(value)));
      }
      return null;
    },
    downloadDocument() {
      if (this.parentModule?.document_name) {
        const self = this;
        this.api.downloadDocumentFile(
          self.parentModule.id,
          function(response) {
            self.api.downloadFileHelper(
              response, self.parentModule.document_name
            );
          },
          function() {
            showErrorDialog(
              'File not found',
              'This file could not be found.'
            );
          }
        );
      }
    },
    isFeaturePredictionType(type) {
      return this.mlModule &&
        [type, this.mlModule.type].some(
          t => consts.FeaturePredTypes.includes(t));
    },
    isPredictionOrUncertaintyType(type) {
      return this.mlModule &&
        [type, this.mlModule.type].some(
          t => consts.PredAndUncertainTypes.includes(t));
    },
    isStructureGeneration() {
      return this.mlModule &&
        [this.mlModule.type].includes('StructureGeneration');
    },
    isStructureFilter(type) {
      return this.mlModule &&
        [type, this.mlModule.type].includes('StructureFilter');
    },
    isObjectiveServer(type) {
      return this.mlModule &&
        [type, this.mlModule.type].includes('ObjectiveServer');
    },
    isUncertaintyType(type) {
      return this.mlModule &&
        ['EDLUncertaintyModel', 'SOODUncertaintyModel'].some(modelType => [type, this.mlModule.type].includes(modelType));
    },
    isLinkableModuleType(type) {
      return this.isFeaturePredictionType(type) ||
        this.isObjectiveServer(type) ||
        this.isUncertaintyType(type);
    },
    isIDAssignableModuleType(type) {
      return this.isFeaturePredictionType(type) ||
        this.isUncertaintyType(type) ||
        this.isStructureFilter(type);
    },
    featureDisplayLabel() {
      const label = 'Simple display name of the prediction target';
      if (this.isMultiplePredictionType()) {
        return label + 's';
      }
      return label;
    },
    fetchSameTypeModels() {
      if (this.mlModule.type && this.imageProvideMethod === 'container') {
        const self = this;
        this.api.loadManageableModels(
          { mlmType: this.mlModule.type },
          this.$session.get('user')?.is_staff ? 'all' : 'user',
          function(list) {
            self.selectableModels = list.map(l => ({ text: l.name, value: l.id }));
          }
        );
      }
    },
    onReferenceModelChange(selectedReferenceModel) {
      showConfirmDialog({
        title: 'Do you want to import the model configuration?',
        html: '',
        approveCB: () => {
          const self = this;
          this.api.downloadConfigFile(
            selectedReferenceModel,
            function(response) {
              self.fillFormFromJSON(response.data);
            },
            function(e) {
              console.log(e);
              showErrorDialog(
                'An unexpected error occurred',
                `Try again or let an administrator
                        know if the problem persists.`
              );
            }
          );
        },
        cancelButtonText: 'No'
      });
    },
    changeRequiresLicense() {
      if (!this.requiresLicense) {
        this.mlModule.license_dir_server = '';
        this.mlModule.license_dir_container = '';
      }
    },
    isMultiplePredictionType() {
      return this.isFeaturePredictionType &&
        this.mlModule.type.startsWith('Multiple');
    },
    isSinglePredictionType() {
      return this.isFeaturePredictionType &&
        this.mlModule.type.startsWith('Single');
    },
    handleFileImport() {
      this.isSelecting = true;
      window.addEventListener('focus', () => {
        this.isSelecting = false;
      }, { once: true });
      this.$refs.uploader.click();
    },
    onExcelImport(e) {
      const extractedData = {};
      const fieldNameMap = this.isMultiplePredictionType()
        ? consts.MultipleFeatureFileMap
        : consts.SingleFeatureFileMap;
      const self = this;
      this.info_file = e.target.files[0];
      readXlsxFile(this.info_file).then((rows) => {
        rows.forEach(row => {
          const keyInFile = row[1];
          const fieldName = fieldNameMap[keyInFile];
          if (keyInFile !== null && fieldName !== undefined) {
            extractedData[fieldName] = row[3] ?? '';
          }
        });
      }).finally(() => {
        self.isRegression = Object.keys(extractedData).includes('r_squared');
        for (const k in extractedData) {
          self.mlModule[k] = extractedData[k];
        }
      });
    },
    onChangeModelIdPolicy() {
      if (this.mlModule.module_id_policy.id_policy === 'ByArgument') {
        const cmpStrucNameIndex = this.inputs.findIndex(
          input => input.name === consts.MandatoryFields.cmpStrucName.name
        );
        const cmpIdName = this.tempIdInputField ??
          consts.MandatoryFields.cmpIdName;
        this.inputs.splice(cmpStrucNameIndex, 0, { ...cmpIdName });
      } else {
        const index = this.inputs.findIndex(
          input => input.name === consts.MandatoryFields.cmpIdName.name
        );
        if (index > -1) {
          this.tempIdInputField = this.inputs.splice(index, 1)[0];
        }
      }
    },
    onUseReward() {
      if (!this.useReward) this.mlModule.reward_config_file = '';
    },
    onUseRnn() {
      if (!this.useRnn) this.mlModule.rnn_volume = '';
    },
    validateOutputFilename(value, index) {
      if (index === 0) {
        if (this.isPredictionOrUncertaintyType() &&
                    !value.endsWith('.csv') && !value.endsWith('.CSV')) {
          return 'The first output file needs to be a CSV file';
        } else if (!value.endsWith('.csv') && !value.endsWith('.CSV') &&
                      !value.endsWith('.sdf') && !value.endsWith('.SDF')) {
          return 'The first output file needs to be a CSV or a SDF file';
        }
      }
      if (value?.trim() === '') {
        return 'The name of the output file is required';
      }
      if (this.outputs.filter(output => output.value.trim() === value.trim()).length >= 2) {
        return 'This name is already used an as ouput file';
      }
      return true;
    },
    countInputSmilesField(inputs) {
      // Count the number of arguments named "input_smiles"
      let inputSmilesAppearInObject = 0;
      let inputSmilesAppearInList = 0;
      for (const input of inputs) {
        if (input.name === 'input_smiles') {
          inputSmilesAppearInObject++;
        }
        if (input.parameters && input.parameters.length > 0) {
          const count = this.countInputSmilesField(input.parameters);
          inputSmilesAppearInObject += count[0];
          inputSmilesAppearInList += count[1];
        }
        if (input.element) {
          const count = this.countInputSmilesField(input.element);
          inputSmilesAppearInObject += count[0];
          inputSmilesAppearInList += count[1];
        }
      }
      return [inputSmilesAppearInObject, inputSmilesAppearInList];
    },
    onChangeAllowedExtPtsSelection(value) {
      if (value === 'ExactlyOne') {
        if (this.countInputSmilesField(this.inputs)[0] === 0) {
          this.inputs.push({ ...consts.MandatoryFields.inputSmiles });
        }
      } else {
        this.inputs = this.inputs.filter(obj => obj.name !== 'input_smiles');
      }
    },
    updateObjVal(updatedValues) {
      this.mlModule.objective_values = updatedValues;
      this.objValDialog = false;
    }
  }
};
</script>

<style scoped>
.inline-radio-text-field {
  display: flex;
  flex-direction: row;
}

.inline-radio-text-field> .id-name {
  margin-left: 10px;
  padding: 6px;
  max-width: 200px;
}

.red-text {
  color: red;
}
</style>
