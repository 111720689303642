import { render, staticRenderFns } from "./ProjectDesign.vue?vue&type=template&id=d015e5b8&scoped=true&"
import script from "./ProjectDesign.vue?vue&type=script&lang=js&"
export * from "./ProjectDesign.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDesign.vue?vue&type=style&index=0&id=d015e5b8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d015e5b8",
  null
  
)

export default component.exports