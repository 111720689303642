<template>
  <div>
    <v-row class="block">
      <v-col class="py-0">
        <h3 class="section-title">
          出発化合物編集
        </h3>
        <CompoundEditor
          :compound="compound"
          kekule-id="kekule-generation-settings"
        />
      </v-col>
      <v-col :cols="v_cols" class="px-5">
        <TargetProfileView
          :compound="compound"
          :profile="profile"
          show-category-name
          show-priority
          :show-tooltip="true"
          @changePriority="changePriority"
        />
        <v-divider class="py-1" />
        <v-btn @click="repredict">
          <v-icon dark left>mdi-repeat</v-icon>
          <span>再予測</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="7" height="100%">
        <v-card class="ml-1" height="100%">
          <v-card-title class="pb-0">構造発生方法</v-card-title>
          <v-card-text class="pb-0">
            <v-row>
              <v-col cols="4" class="pb-0">
                <v-select
                  v-model="selectedModule"
                  :items="modules"
                  item-value="id"
                  item-text="name"
                  label="AI model"
                  return-object
                  dense
                  outlined
                  depressed
                  @change="onSelectModule"
                />
              </v-col>
              <v-col cols="2" class="pb-0">
                <v-btn
                  color="indigo"
                  max-height="40px"
                  min-height="40px"
                  depressed
                  outlined
                  @click="toggleOption"
                >
                  詳細設定
                </v-btn>
              </v-col>
              <v-col>
                <SettingsAssistantButton class="ml-3" />
              </v-col>
            </v-row>
            <div v-show="selectedModule != null && showModuleConfig">
              <div
                v-if="selectedModule && selectedModule.config"
                class="indent border-box"
              >
                <div
                  v-for="(info, name) in selectedModule.config.input || {}"
                  :key="name"
                >
                  <ModuleInput
                    :name="name"
                    :info="info"
                    :value="genSettingProp?.sgModelParams ? genSettingProp.sgModelParams[name] : null"
                    @changeParameter="onChangeSGParams"
                    @setFile="onChangeSGFiles"
                  />
                </div>
              </div>
              <v-row v-if="selectedModule && selectedModule.rnn_volume">
                <v-col>
                  <RnnVolumeSelection
                    ref="rnnVolumeSelection"
                    :value="genSettingProp?.sgModelParams ? genSettingProp.sgModelParams.rnn_volume : null"
                    @select="onRnnVolumeSelect"
                  />
                </v-col>
              </v-row>
              <v-row v-if="selectedModule && selectedModule.reward_config_file">
                <v-col>
                  <div
                    v-for="(info, name) in rewardSetting"
                    :key="name"
                  >
                    <ModuleInput
                      ref="children"
                      :name="name"
                      :info="info"
                      :value="genSettingProp?.rewardConfig && !rewardSettingValue ? genSettingProp?.rewardConfig : rewardSettingValue"
                      :all-required="true"
                      @changeParameter="onChangeSGParams"
                      @setFile="onChangeSGFiles"
                    />
                  </div>
                </v-col>
              </v-row>
            </div>
            <!-- Referenced to issue #1349 -->
            <template v-if="false">
              <v-row no-gutters>
                <v-col cols="4">
                  <p class="text-right pt-1 mb-0">新規性</p>
                </v-col>
                <v-col cols="4">
                  <v-slider
                    v-model="intentions.novelty"
                    ticks="always"
                    tick-size="4"
                    step="1"
                    max="5"
                    min="1"
                    :disabled="!isChemTsReward(selectedModule)"
                    dense
                    @change="onChangeSlider('novelty')"
                  />
                </v-col>
                <v-col cols="4">
                  <p class="pt-1 mb-0">既存医薬品らしさ</p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4">
                  <p class="text-right pt-1 mb-0">短い計算時間</p>
                </v-col>
                <v-col cols="4">
                  <v-slider
                    v-model="intentions.computation"
                    ticks="always"
                    tick-size="4"
                    step="1"
                    max="5"
                    min="1"
                    :disabled="!isChemTsReward(selectedModule)"
                    dense
                    @change="onChangeSlider('computation')"
                  />
                </v-col>
                <v-col cols="4">
                  <p class="pt-1 mb-0">網羅性</p>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="4">
                  <p class="text-right pt-1 mb-0">合成が難しくても良い</p>
                </v-col>
                <v-col cols="4">
                  <v-slider
                    v-model="intentions.synthetic"
                    ticks="always"
                    tick-size="4"
                    step="1"
                    max="5"
                    min="1"
                    :disabled="!isChemTsReward(selectedModule)"
                    dense
                    @change="onChangeSlider('synthetic')"
                  />
                </v-col>
                <v-col cols="4">
                  <p class="pt-1 mb-0">合成容易</p>
                </v-col>
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="5">
        <v-row>
          <v-card class="ml-3 mb-3" width="100%">
            <v-card-title>フィルタ</v-card-title>
            <v-card-subtitle class="pb-1">適用するフィルタを選択してください。</v-card-subtitle>
            <v-card-text class="pb-4">
              <v-layout wrap>
                <div v-for="(filter, idx) in filters" :key="filter.id">
                  <v-checkbox
                    v-model="selectedFilters"
                    hide-details="auto"
                    class="d-flex mr-5"
                    :label="filter.name"
                    :value="filter.id"
                    @click.stop="filter.showDialog = selectedFilters.includes(filter.id)"
                  />
                  <v-dialog
                    v-if="filter.hasEditableField"
                    v-model="filter.showDialog"
                    persistent
                    width="600"
                  >
                    <v-card>
                      <v-card-title class="text-h5">
                        実行に必要な項目を入力してください。
                      </v-card-title>

                      <v-card-text>
                        <v-form
                          :ref="`filterForm${filter.id}`"
                          v-model="filter.valid"
                          lazy-validation
                          @submit.prevent
                        >
                          <div
                            v-for="(info, name) in filter.config.input || {}"
                            :key="name"
                          >
                            <ModuleInput
                              :name="name"
                              :info="info"
                              :value="filterParameters[filter.id] ? filterParameters[filter.id][name] || null : null"
                              :all-required="true"
                              @changeParameter="onChangeFilterParams(idx, ...arguments)"
                              @setFile="onChangeFilterFiles(idx, ...arguments)"
                            />
                          </div>
                        </v-form>
                      </v-card-text>

                      <v-card-actions>
                        <v-spacer />

                        <v-btn
                          color="warning"
                          text
                          @click="onCancelFilter(filter)"
                        >
                          キャンセル
                        </v-btn>

                        <v-btn
                          :disabled="!filter.valid"
                          color="info"
                          text
                          @click="onActivateFilter(filter)"
                        >
                          有効化
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-row>
        <!-- Referenced to issue #1349 -->
        <v-row v-if="false">
          <v-card class="ml-3 mt-3" width="100%">
            <v-card-title class="pb-1">実行結果予測</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  出力化合物数： <strong class="big-number mr-5">
                    ≈{{ isChemTsReward(selectedModule) ? expected_generated_compounds : '-' }}
                  </strong>
                </v-col>
                <v-col cols="6">
                  <v-icon>mdi-clock-fast</v-icon><strong class="big-number">
                    ≈{{ isChemTsReward(selectedModule) ? expected_execution_time : '-' }}
                  </strong>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import CompoundEditor from '@/components/design/Project/CompoundEditor';
import TargetProfileView from '@/components/design/TargetProfileView';
import ModuleInput from '@/components/ModuleInput';
import RnnVolumeSelection from '@/components/RnnVolumeSelection';
import SettingsAssistantButton from '@/components/SettingsAssistantButton';
import { Kekule } from 'kekule';
import consts from '@/store/consts';

export default {
  name: 'GenerationSettings',
  components: {
    ModuleInput,
    CompoundEditor,
    TargetProfileView,
    RnnVolumeSelection,
    SettingsAssistantButton
  },
  props: {
    genSettingProp: {
      type: Object,
      default: () => null
    },
    originalFilters: {
      type: Array,
      default: () => []
    },
    compound: {
      type: Object,
      default: () => null
    },
    profile: {
      type: Array,
      default: () => []
    },
    rewardSettingValue: {
      type: Object,
      default: () => null
    },
    modules: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      parameters: Object(),
      files: Object(),
      selectedModule: null,
      showModuleConfig: false,
      selectedFilters: [],
      expected_execution_time: '',
      expected_generated_compounds: 0,
      intentions: {
        novelty: 3,
        computation: 3,
        synthetic: 3
      },
      description: '',
      filters: [],
      filterParameters: Object(),
      filterFiles: Object(),
      rewardSetting: { ...consts.RewardSetting }
    };
  },
  computed: {
    breakpoint: function() {
      return this.$vuetify.breakpoint.name;
    },
    v_cols: function() {
      switch (this.breakpoint) {
      case 'xl':
      case 'lg':
        return 7;
      default:
        return 6;
      }
    }
  },
  watch: {
    modules: {
      handler() {
        if (this.selectedModule === null) {
          this.selectedModule = this.modules[0];
          this.onChangeSlider('novelty');
        }
      },
      deep: true
    },
    originalFilters: {
      handler() {
        this.filters = this.originalFilters.map(f => {
          const newFilter = { ...f };
          newFilter.config = JSON.parse(newFilter.config);
          if (newFilter.config.input) {
            delete newFilter.config.input[consts.MandatoryFields.cmpFile.name];
            delete newFilter.config.input[consts.MandatoryFields.cmpIdName.name];
            delete newFilter.config.input[consts.MandatoryFields.cmpStrucName.name];
            delete newFilter.config.input[consts.MandatoryFields.predFilValName.name];
          }
          newFilter.hasEditableField = Object.keys(newFilter.config.input || {}).length > 0;
          if (newFilter.hasEditableField) {
            this.filterParameters[newFilter.id] = Object();
            this.filterFiles[newFilter.id] = Object();
          }
          newFilter.valid = false;
          newFilter.showDialog = false;
          return newFilter;
        });
      }
    },
    selectedModule: {
      handler() {
        this.$emit('selectSgModule', this.selectedModule);
      }
    },
    genSettingProp: {
      handler() {
        if (this.genSettingProp) {
          const sgModule = this.modules.find(
            module => module.id === this.genSettingProp.sgModelId
          );
          if (sgModule) {
            this.selectedModule = sgModule;
          }
          const {
            sgModelFiles, filterModelParams,
            filterModelIds, filterModelFiles
          } = this.genSettingProp;
          this.files = sgModelFiles ? JSON.parse(JSON.stringify(sgModelFiles)) : {};
          this.selectedFilters = filterModelIds ? JSON.parse(JSON.stringify(filterModelIds)) : [];
          this.filterParameters = filterModelParams ? JSON.parse(JSON.stringify(filterModelParams)) : {};
          this.filterFiles = filterModelFiles ? JSON.parse(JSON.stringify(filterModelFiles)) : {};
          if (this.parameters.reward_config && this.genSettingProp.rewardConfig) {
            for (const parentReward of this.genSettingProp.rewardConfig.settings) {
              const reward = this.parameters.reward_config.settings
                .find(item => item.origin === parentReward.origin);
              if (reward) {
                reward.name = parentReward.name;
                reward.weight = parentReward.weight || 1;
              }
            }
          }
        }
      },
      deep: true
    }
  },
  mounted() {},
  methods: {
    onChangeFilterParams(idx, ...args) {
      const [name, value] = args;
      this.updateModuleParams(name, value, this.filterParameters[this.filters[idx].id], true);
    },
    onChangeSGParams(...args) {
      const [name, value] = args;
      this.updateModuleParams(name, value, this.parameters, true);
    },
    onChangeFilterFiles(idx, ...args) {
      const [name, file] = args;
      this.updateModuleParams(name, file, this.filterFiles[this.filters[idx].id], false);
    },
    onChangeSGFiles(...args) {
      const [name, file] = args;
      if (file === undefined && this.files[name]) {
        return null; // if file are not selected use the previous file
      }
      this.updateModuleParams(name, file, this.files, false);
    },
    updateModuleParams(name, value, params, updateCalcTime) {
      params[name] = value;
      if (updateCalcTime) {
        this.calculateExpectations();
      }
    },
    onCancelFilter(filter) {
      this.selectedFilters = this.selectedFilters.filter(filterId => filterId !== filter.id);
      filter.showDialog = false;
    },
    onActivateFilter(filter) {
      if (this.$refs[`filterForm${filter.id}`][0].validate()) {
        filter.showDialog = false;
      }
    },
    toggleOption() {
      this.showModuleConfig = !this.showModuleConfig;
    },
    onSelectModule() {
      if (this.selectedModule?.reward_config_file) {
        this.parameters = {
          'reward_config': this.parameters.reward_config || {}
        };
        Object.keys(this.files).forEach(key => {
          if (!key.startsWith('reward_config')) delete this.files[key];
        });
      } else {
        this.parameters = {};
        this.files = {};
      }
      this.onChangeSlider('novelty');
    },
    onChangeSlider(slider) {
      const conf = this.selectedModule.config.input?.chemts_configs?.parameters;
      if (!conf) {
        return;
      }
      if (this.intentions.computation === 1) {
        conf.hours.default = 0.02; // 1 minute
        conf.trial.default = 1;
      } else if (this.intentions.computation === 2) {
        conf.hours.default = 1; // 1 hour
        conf.trial.default = 1;
      } else if (this.intentions.computation === 3) {
        conf.hours.default = 2; // 2 hours
        conf.trial.default = 1;
      } else if (this.intentions.computation === 4) {
        conf.hours.default = 2;
        conf.trial.default = 2; // 2 x 2 hours
      } else if (this.intentions.computation === 5) {
        conf.hours.default = 4;
        conf.trial.default = 2; // 4 x 2 hours
      }
      if (slider === 'novelty') {
        conf.use_hashimoto_filter.default = this.intentions.novelty === 5
          ? 'false'
          : 'true';
      }
      // Currently, "synthetic" has no impact on config
    },
    calculateExpectations() {
      if (!this.parameters?.chemts_configs) {
        return;
      }
      const trial = this.parameters.chemts_configs.trial || 0;
      const hours = this.parameters.chemts_configs.hours || 0;
      // The trend on test server follows the equation
      // y = 3231 * x + 448
      this.expected_generated_compounds = Math.floor(3231 * hours * trial + 448);
      const nbHours = Math.floor(hours * trial);
      const nbMinutes = Math.ceil(hours * trial % 1 * 60);
      this.expected_execution_time = '';
      if (nbHours !== 0) {
        this.expected_execution_time += `${nbHours}時間`;
      }
      if (nbMinutes !== 0) {
        this.expected_execution_time += `${nbMinutes}分`;
      }
    },
    changePriority(item, priority) {
      this.$emit('changePriority', item.id, priority);
    },
    submit() {
      // Kekuleエディタが返すSMILESに問題がある
      // input_smilesに何か入力されていたら、エディタが空でもinput_smilesを空にしない
      // 1/23のデモ用のテンポラリーな修正
      const inputedSmiles = this.convertedSmiles();
      if (inputedSmiles.length !== 0) {
        this.parameters.input_smiles = inputedSmiles;
      }

      for (const filterId in this.filterParameters) {
        if (!this.selectedFilters.includes(Number(filterId))) {
          delete this.filterParameters[filterId];
        }
      }
      for (const filterId in this.filterFiles) {
        if (!this.selectedFilters.includes(Number(filterId))) {
          delete this.filterFiles[filterId];
        }
      }
      // The smiles will be rewritten so that atom_idx is always one
      this.parameters.atom_idx = '1';
      const generationSetting = {
        sg_model_id: this.selectedModule.id,
        sg_model_params: this.parameters,
        sg_model_files: this.files,
        filter_model_ids: this.selectedFilters,
        filter_model_params: this.filterParameters,
        filter_model_files: this.filterFiles // Actuary, real necessary things are dictionary structure.
      };
      return [generationSetting, this.files, this.filterFiles];
    },
    convertedSmiles() {
      const composer = Kekule.Widget.getWidgetOnElem(
        document.getElementById('kekule-generation-settings')
      );
      const smiles = Kekule.IO.saveFormatData(
        composer.getChemObj(),
        'smi'
      );
      return smiles;
    },
    repredict() {
      this.$emit('repredict');
    },
    isChemTsReward(module) {
      if (module && ['ChemTS v1.1.2 Reward'].includes(module.name)) {
        return true;
      } else {
        return false;
      };
    },
    onRnnVolumeSelect(volume) {
      this.updateModuleParams('rnn_volume', volume, this.parameters, false);
    },
    validate() {
      if (this.selectedModule.rnn_volume && !this.$refs.rnnVolumeSelection.validatedForm()) {
        return false;
      }
      if (this.selectedModule.reward_config_file) {
        return this.$refs.children
          .map(child => child.validatedForm())
          .flat(Infinity)
          .every(valid => valid);
      }
      return true;
    }
  }
};
</script>

<style scoped>
.v-list-item {
    max-height: 40px;
}
.section-title {
    font-family: "Roboto", sans-serif;
    font-size: 20px;
    font-weight: 500
}
.text-right {
    text-align: right;
}
.big-number {
    font-size: 18px;
}
:deep(.v-slider__tick--filled) {
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
