const MandatoryFields = {
  cmpFile: {
    'name': 'compounds_file',
    'file_type': [],
    'type': 'file',
    'description': 'List of compounds (SDF or CSV)',
    'disabled': true
  },
  cmpIdName: {
    'name': 'compound_identifier_name',
    'type': 'string',
    'description': 'Field name of the identifier',
    'disabled': true
  },
  cmpStrucName: {
    'name': 'compound_structure_name',
    'type': 'string',
    'description': 'Field name of the structure (only for CSV)',
    'disabled': true
  },
  predValName: {
    'name': 'predicted_value_name',
    'type': 'string',
    'description': 'Field name of the predicted value',
    'disabled': true
  },
  predFilValName: {
    'name': 'predicted_value_name',
    'type': 'string',
    'description': 'Field name of the predicted filter value (0 or 1)',
    'disabled': true
  },
  inputSmiles: {
    'name': 'input_smiles',
    'type': 'smiles',
    'description': 'Field name of the input smiles',
    'disabled': true
  }
};

const FeaturePredTypes = [
  'SingleFeaturePrediction',
  'MultipleFeaturePrediction'
];

const PredAndUncertainTypes = [
  'SingleFeaturePrediction',
  'MultipleFeaturePrediction',
  'EDLUncertaintyModel',
  'SOODUncertaintyModel'
];

const PredictionModelFields = [
  'feature_display',
  'unit',
  'category',
  'number_of_tasks',
  'prediction_target',
  'explanatory_variable',
  'prediction_algorithm',
  'size_of_training_data',
  'benchmark',
  'r_squared',
  'roc_score'
];

const SingleFeatureInfoFileMap = {
  'model_name': 'name',
  'category': 'category',
  'objective': 'prediction_target',
  'objectives': 'prediction_target',
  'output_type': 'unit',
  'explanatory_variables': 'explanatory_variable',
  'algorithm': 'prediction_algorithm',
  'data_size': 'size_of_training_data',
  'mean_data_size': 'size_of_training_data',
  'test_method': 'benchmark',
  'mean_R2': 'r_squared',
  'ROC score': 'roc_score'
};

const MultipleFeatureInfoFileMap = {
  'model_name': 'name',
  'category': 'category',
  'number_of_tasks': 'number_of_tasks',
  'objectives': 'prediction_target',
  'output_type': 'unit',
  'explanatory_variables': 'explanatory_variable',
  'algorithm': 'prediction_algorithm',
  'mean_data_size': 'size_of_training_data',
  'test_method': 'benchmark',
  'mean_R2': 'r_squared',
  'mean_ROC score': 'roc_score'
};

const LinkableModelType = [
  'Single Feature Prediction',
  'Multiple Feature Prediction',
  'Objective Server',
  'EDL Uncertainty Model',
  'SOOD Uncertainty Model'
];

const RewardSetting = {
  'reward_config': {
    'type': 'yaml',
    'description': 'Config of reward',
    'disabled': false,
    'filename': 'reward_config.yml',
    'is_reward': true,
    'parameters': {
      'settings': {
        'type': 'list',
        'description': 'Settings of reward',
        'filename': '',
        'initiallyEmpty': true,
        'is_reward': true,
        'element': {
          'origin': {
            'type': 'string',
            'description': 'Reward model',
            'is_reward': true
          },
          'objective_value': {
            'type': 'string',
            'description': 'Objective value',
            'is_reward': true
          },
          'display_name': {
            'type': 'string',
            'description': 'Display Name',
            'is_reward': true
          },
          'weight': {
            'type': 'integer',
            'description': 'Weight of reward',
            'is_reward': true
          },
          'scaling': {
            'type': 'select',
            'description': 'Scaling',
            'options': [
              { 'value': 'none', 'text': 'None' },
              { 'value': 'step', 'text': 'Step' },
              { 'value': 'threshold', 'text': 'Threshold' },
              { 'value': 'range', 'text': 'Range' },
              { 'value': 'sigmoid', 'text': 'Sigmoid' },
              { 'value': 'max_gauss', 'text': 'Max Gauss' }
            ],
            'is_reward': true
          },
          'good_value': {
            'type': 'select',
            'description': 'Good Value',
            'options': [
              { 'value': 'low', 'text': 'Low' },
              { 'value': 'high', 'text': 'High' }
            ],
            'is_reward': true
          }
        }
      }
    }
  }
};

const RewardGoodValueFields = {
  'step': {
    'type': 'select',
    'description': 'Good Value',
    'options': [
      { 'value': 'low', 'text': 'Low' },
      { 'value': 'high', 'text': 'High' }
    ],
    'is_reward': true
  },
  'threshold': {
    'type': 'select',
    'description': 'Good Value',
    'options': [
      { 'value': 'low', 'text': 'Low' },
      { 'value': 'high', 'text': 'High' }
    ],
    'is_reward': true
  },
  'range': {
    'type': 'select',
    'description': 'Good Value',
    'options': [
      { 'value': 'in', 'text': 'In' },
      { 'value': 'out', 'text': 'Out' }
    ],
    'is_reward': true
  },
  'sigmoid': {
    'type': 'select',
    'description': 'Good Value',
    'options': [
      { 'value': 'low', 'text': 'Low' },
      { 'value': 'high', 'text': 'High' }
    ],
    'is_reward': true
  },
  'max_gauss': {
    'type': 'select',
    'description': 'Good Value',
    'options': [
      { 'value': 'low', 'text': 'Low' },
      { 'value': 'high', 'text': 'High' }
    ],
    'is_reward': true
  }
};

const RewardThresholdFields = {
  'threshold': {
    'type': 'dictionary',
    'description': 'Threshold',
    'element': {
      'value1': {
        'type': 'float',
        'description': 'Value 1',
        'is_reward': true
      },
      'value2': {
        'type': 'float',
        'description': 'Value 2',
        'is_reward': true
      }
    },
    'is_reward': true
  },
  'range': {
    'type': 'dictionary',
    'description': 'Threshold',
    'element': {
      'value1': {
        'type': 'float',
        'description': 'Value 1',
        'is_reward': true
      },
      'value2': {
        'type': 'float',
        'description': 'Value 2',
        'is_reward': true
      },
      'value3': {
        'type': 'float',
        'description': 'Value 3',
        'is_reward': true
      },
      'value4': {
        'type': 'float',
        'description': 'Value 4',
        'is_reward': true
      }
    },
    'is_reward': true
  },
  'sigmoid': {
    'type': 'dictionary',
    'description': 'Threshold',
    'element': {
      'value1': {
        'type': 'float',
        'description': 'Value 1',
        'is_reward': true
      },
      'value2': {
        'type': 'float',
        'description': 'Value 2',
        'is_reward': true
      }
    },
    'is_reward': true
  },
  'step': {
    'type': 'dictionary',
    'description': 'Threshold',
    'element': {
      'value1': {
        'type': 'float',
        'description': 'Value 1',
        'is_reward': true
      }
    },
    'is_reward': true
  },
  'max_gauss': {
    'type': 'dictionary',
    'description': 'Threshold',
    'element': {
      'value1': {
        'type': 'float',
        'description': 'Value 1',
        'is_reward': true
      },
      'value2': {
        'type': 'float',
        'description': 'Value 2',
        'is_reward': true
      }
    },
    'is_reward': true
  }
};

const UnusedFieldsByModelType = {
  'StructureGeneration': ['moduleToLink', 'osForUncertainty'],
  'StructureFilter': ['moduleToLink', 'reward_config_file', 'osForUncertainty'],
  'ObjectiveServer': [
    'output', 'status', 'module_id_policy',
    'reward_config_file', 'osForUncertainty'
  ],
  'SingleFeaturePrediction': ['reward_config_file', 'osForUncertainty'],
  'MultipleFeaturePrediction': ['reward_config_file', 'osForUncertainty'],
  'EDLUncertaintyModel': ['reward_config_file'],
  'SOODUncertaintyModel': ['reward_config_file']
};

const defaultCategoryOrder = ['Potency', 'Toxicity', 'ADME', 'Property', 'Other'];

export default {
  MandatoryFields: MandatoryFields,
  FeaturePredTypes: FeaturePredTypes,
  PredAndUncertainTypes: PredAndUncertainTypes,
  PredictionModelFields: PredictionModelFields,
  SingleFeatureFileMap: SingleFeatureInfoFileMap,
  MultipleFeatureFileMap: MultipleFeatureInfoFileMap,
  LinkableModelType: LinkableModelType,
  RewardSetting: RewardSetting,
  RewardThresholdFields: RewardThresholdFields,
  RewardGoodValueFields: RewardGoodValueFields,
  UnusedFieldsByModelType: UnusedFieldsByModelType,
  defaultCategoryOrder: defaultCategoryOrder
};
