<template>
  <v-simple-table>
    <template #default>
      <ProfileParametersInput
        ref="profileParametersInput"
        @confirm="confirmDialog"
      />
      <colgroup>
        <col style="width: 35%;">
        <col style="width: 40%;">
        <col style="width: 20%;">
        <col style="width: 5%;">
      </colgroup>
      <thead>
        <tr>
          <th
            v-for="(header, index) in headers"
            :key="index"
            class="text-left"
          >
            <div style="display: flex; align-items: center;">
              <span>{{ header.value }}</span>
              <v-tooltip v-if="header.tooltip" top>
                <template #activator="{ on, attrs }">
                  <v-icon
                    dense
                    small
                    v-bind="attrs"
                    class="ml-2"
                    v-on="on"
                  >
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                <span>{{ header.tooltip }}</span>
              </v-tooltip>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(target, i) in targets" :key="target.initialName">
          <td>
            <div>
              <v-select
                v-model="target.initialName"
                :items="targetNames"
                label="ファイルからの項目(任意)"
                hide-details
                @change="onSdfItemChange(target)"
              />
            </div>
            <div>
              <GroupedAutocomplete
                v-model="target.predictionModel"
                :selected-value="target.predictionModel"
                :items="selectableModules(target)"
                item-value="id"
                :item-text="(module) => module.verbose_name"
                label="予測モデル"
                group-name="category"
                :disabled="
                  target.initialName != '' &&
                    target.initialName != null
                "
                class="pb-1"
                hide-details
                @change="onPredictionModelSelect(target)"
              >
                <template #icon>
                  <v-tooltip v-if="target['predictionModel']" bottom>
                    <template #activator="{ on: tooltipOn, attrs: tooltipAttrs }">
                      <div v-bind="tooltipAttrs" v-on="tooltipOn">
                        <v-icon
                          color="primary"
                          @click="onDialogOpen(target, i)"
                        >
                          mdi-code-braces
                        </v-icon>
                      </div>
                    </template>
                    <span>モデル情報とパラメーター設定。</span>
                  </v-tooltip>
                </template>
              </GroupedAutocomplete>
            </div>
          </td>
          <td>
            <vue-slider
              :value="dotsPositions(target)"
              :order="false"
              :process="dotsPos => dotsProcess(target, dotsPos)"
              :min="0"
              :max="100"
              :marks="marks(target)"
              class="pt-8"
              height="10px"
              tooltip="always"
              disabled
            >
              <template #tooltip="{ value }">
                <div
                  v-show="target.scalingType != 'none'"
                  class="
                    slider-tooltip
                    vue-slider-dot-tooltip-inner
                    vue-slider-dot-tooltip-inner-top
                  "
                >
                  <input
                    :value="tooltipDisplayed(target, value)"
                    class="slider-tooltip"
                    @input="
                      changeRangeValue(
                        target,
                        $event,
                        value
                      )
                    "
                  >
                </div>
              </template>
            </vue-slider>
            <div class="error-message message mt-2">
              {{ target.rangeError || "" }}
            </div>
          </td>
          <td>
            <div>
              <v-select
                v-model="target.scalingType"
                :items="scalingTypes"
                item-value="value"
                item-text="label"
                label="Scaling"
              />
            </div>
            <div>
              <v-checkbox
                v-if="target.scalingType != 'none'"
                v-model="target.invert"
                dense
                height="20px"
                class="mt-0"
                label="色反転"
                hide-details
                @click="removeError(target)"
              />
            </div>
          </td>
          <td>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-icon
                  color="red"
                  dense
                  v-bind="attrs"
                  @click="deleteTarget(i)"
                  v-on="on"
                >
                  mdi-delete-forever-outline
                </v-icon>
              </template>
              <span>削除</span>
            </v-tooltip>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
import GroupedAutocomplete from '@/components/GroupedAutocomplete.vue';
import ProfileParametersInput from
  '@/components/design/Project/ProfileParametersInput.vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import variables from '@/styles/profile_table.module.scss';
import { isEmpty, truncTo } from '@/mixins/utils';
import consts from '@/store/consts';

export default {
  name: 'ProfileSettingsTable',
  components: {
    VueSlider,
    GroupedAutocomplete,
    ProfileParametersInput
  },
  props: {
    targets: {
      type: Array,
      default: () => []
    },
    targetNames: {
      type: Array,
      default: () => []
    },
    modules: {
      type: Array,
      default: () => []
    },
    category: {
      type: String,
      default: ''
    },
    usedModuleIds: {
      type: Array,
      default: () => []
    },
    scalingTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      headers: [
        { value: '予測設定', tooltip: '参考にしたいSDFプロパティか予測モデルを選択する。' },
        { value: 'カラー設定', tooltip: '閾値を設定する。' },
        { value: '', tooltip: '' },
        { value: '', tooltip: '' }
      ]
    };
  },
  methods: {
    selectableModules(target) {
      const unusedModules = this.sortByCategory(
        this.modules.filter(
          module => !this.usedModuleIds.includes(module.id) || module.id === target.predictionModel
        ),
        target.category
      );
      return target.initialName
        ? unusedModules.filter(
          (m) => m.type !== 'MultipleFeaturePrediction'
        )
        : unusedModules;
    },
    deleteTarget(index) {
      this.$emit('deleteTarget', this.category, index);
    },
    // Slider
    marks(target) {
      if (target.scalingType === 'none') {
        return {
          0: '0',
          100: '1'
        };
      } else {
        return {
          0: 'Min',
          100: 'Max'
        };
      }
    },
    readRangeInput(value, oldValue) {
      value = value.trim();
      const pattern = /^[-]?([1-9]\d*|0)(\.\d{3,})?$/;
      if (value === '-' || value === '') {
        return value;
      } else if (pattern.test(value)) {
        return truncTo(value, 2);
      } else if (!isNaN(value)) {
        return value;
      } else {
        return oldValue;
      }
    },
    validateRangeValue(target) {
      if (target.initialName || target.predictionModel) {
        let isValueEmpty = false;
        let isBadOrder = false;
        if (target.scalingType === 'none') {
          // There is no value to validate when using None scaling
          return true;
        } else if (target.scalingType === 'step') {
          if (isEmpty(target.value1)) {
            isValueEmpty = true;
          }
        } else if (['threshold', 'sigmoid', 'max_gauss'].includes(target.scalingType)) {
          if (!isEmpty(target.value1) && !isEmpty(target.value2)) {
            isBadOrder = target.value1 > target.value2;
          } else {
            isValueEmpty = true;
          }
        } else if (target.scalingType === 'range') {
          if (!isEmpty(target.value1) && !isEmpty(target.value2) &&
              !isEmpty(target.value3) && !isEmpty(target.value4)) {
            isBadOrder = target.value1 > target.value2 ||
              target.value2 > target.value3 ||
              target.value3 > target.value4;
          } else {
            isValueEmpty = true;
          }
        }
        if (isValueEmpty) {
          this.$set(target, 'rangeError', '閾値の値が必須');
          return false;
        } else if (isBadOrder) {
          this.$set(target, 'rangeError', '閾値の大小関係が正しくない');
          return false;
        } else {
          this.removeError(target);
          return true;
        }
      }
      return true;
    },
    tooltipDisplayed(target, value) {
      if (target.scalingType === 'none') {
        target.value1 = null;
        target.value2 = null;
        target.value3 = null;
        target.value4 = null;
        target.invert = false;
        this.removeError(target);
      }
      if (target.scalingType === 'step') {
        // The two values of Step scaling are always the same
        // Which will be handled in the backend when submitting
        // Project Design for execution
        target.value2 = null;
        target.value3 = null;
        target.value4 = null;
        return target.value1;
      } else if (['threshold', 'sigmoid', 'max_gauss'].includes(target.scalingType)) {
        target.value3 = null;
        target.value4 = null;
        if (value === 33) {
          return target.value1;
        } else if (value === 66) {
          return target.value2;
        }
      } else if (target.scalingType === 'range') {
        if (value === 20) {
          return target.value1;
        } else if (value === 40) {
          return target.value2;
        } else if (value === 60) {
          return target.value3;
        } else if (value === 80) {
          return target.value4;
        }
      }
    },
    changeRangeValue(target, event, positionValue) {
      // Number('') will give 0
      const oldValue = this.tooltipDisplayed(target, positionValue);
      const rangeValue = this.readRangeInput(event.target.value, oldValue);
      event.target.value = rangeValue;
      let newValue = parseFloat(rangeValue);
      if (isNaN(newValue)) {
        newValue = null;
      }

      if (target.scalingType === 'step') {
        this.$set(target, 'value1', newValue);
        this.$set(target, 'value2', newValue);
      } else if (['threshold', 'sigmoid', 'max_gauss'].includes(target.scalingType)) {
        if (positionValue === 33) {
          this.$set(target, 'value1', newValue);
        } else if (positionValue === 66) {
          this.$set(target, 'value2', newValue);
        }
      } else if (target.scalingType === 'range') {
        if (positionValue === 20) {
          this.$set(target, 'value1', newValue);
        } else if (positionValue === 40) {
          this.$set(target, 'value2', newValue);
        } else if (positionValue === 60) {
          this.$set(target, 'value3', newValue);
        } else if (positionValue === 80) {
          this.$set(target, 'value4', newValue);
        }
      }
      this.removeError(target);
    },
    dotsPositions(target) {
      if (target.scalingType === 'none') {
        // No dot displayed for None scaling
      } else if (target.scalingType === 'step') {
        return 50;
      } else if (['threshold', 'sigmoid', 'max_gauss'].includes(target.scalingType)) {
        return [33, 66];
      } else if (target.scalingType === 'range') {
        return [20, 40, 60, 80];
      }
    },
    dotsProcess(target, dotsPos) {
      if (target.scalingType === 'none') {
        return [
          [
            0,
            100,
            { backgroundColor: variables.profileNeutral }
          ]
        ];
      } else if (target.scalingType === 'step') {
        if (!target.invert) {
          // Red to green
          return [
            [
              0,
              dotsPos[0],
              { backgroundColor: variables.profileInactive }
            ],
            [
              dotsPos[0],
              100,
              { backgroundColor: variables.profileActive }
            ]
          ];
        } else {
          // Green to red
          return [
            [
              0,
              dotsPos[0],
              { backgroundColor: variables.profileActive }
            ],
            [
              dotsPos[0],
              100,
              { backgroundColor: variables.profileInactive }
            ]
          ];
        }
      } else if (['threshold', 'sigmoid', 'max_gauss'].includes(target.scalingType)) {
        if (!target.invert) {
          // Red to green
          return [
            [
              0,
              dotsPos[0],
              { backgroundColor: variables.profileInactive }
            ],
            [
              dotsPos[0],
              dotsPos[1],
              { backgroundColor: variables.profileNeutral }
            ],
            [
              dotsPos[1],
              100,
              { backgroundColor: variables.profileActive }
            ]
          ];
        } else {
          // Green to red
          return [
            [
              0,
              dotsPos[0],
              { backgroundColor: variables.profileActive }
            ],
            [
              dotsPos[0],
              dotsPos[1],
              { backgroundColor: variables.profileNeutral }
            ],
            [
              dotsPos[1],
              100,
              { backgroundColor: variables.profileInactive }
            ]
          ];
        }
      } else if (target.scalingType === 'range') {
        if (!target.invert) {
          // Red to green to red
          return [
            [
              0,
              dotsPos[0],
              { backgroundColor: variables.profileInactive }
            ],
            [
              dotsPos[0],
              dotsPos[1],
              { backgroundColor: variables.profileNeutral }
            ],
            [
              dotsPos[1],
              dotsPos[2],
              { backgroundColor: variables.profileActive }
            ],
            [
              dotsPos[2],
              dotsPos[3],
              { backgroundColor: variables.profileNeutral }
            ],
            [
              dotsPos[3],
              100,
              { backgroundColor: variables.profileInactive }
            ]
          ];
        } else {
          // Green to red to green
          return [
            [
              0,
              dotsPos[0],
              { backgroundColor: variables.profileActive }
            ],
            [
              dotsPos[0],
              dotsPos[1],
              { backgroundColor: variables.profileNeutral }
            ],
            [
              dotsPos[1],
              dotsPos[2],
              { backgroundColor: variables.profileInactive }
            ],
            [
              dotsPos[2],
              dotsPos[3],
              { backgroundColor: variables.profileNeutral }
            ],
            [
              dotsPos[3],
              100,
              { backgroundColor: variables.profileActive }
            ]
          ];
        }
      }
      if (dotsPos.length === 1) {
        return [
          [
            0,
            dotsPos[0],
            { backgroundColor: variables.profileActive }
          ],
          [
            dotsPos[0],
            100,
            { backgroundColor: variables.profileInactive }
          ]
        ];
      } else {
        return [
          [
            0,
            dotsPos[0],
            { backgroundColor: variables.profileActive }
          ],
          [
            dotsPos[0],
            dotsPos[1],
            { backgroundColor: variables.profileNeutral }
          ],
          [
            dotsPos[1],
            100,
            { backgroundColor: variables.profileInactive }
          ]
        ];
      }
    },
    validateRanges() {
      return this.targets.every((t) => this.validateRangeValue(t));
    },
    removeError(target) {
      this.$delete(target, 'rangeError');
    },
    clickInfoIcon(target) {
      this.$emit('clickInfoIcon', target.predictionModel);
    },
    onSdfItemChange(target) {
      if (
        this.modules.find((m) => m.id === target.predictionModel)
          ?.type === 'MultipleFeaturePrediction'
      ) {
        target.predictionModel = null;
      }
    },
    sortByCategory(modules, inputValue) {
      const categoryOrder = this.moveToTopIfMatch(consts.defaultCategoryOrder, inputValue);
      modules.sort(
        (a, b) => categoryOrder.indexOf(a.category) - categoryOrder.indexOf(b.category)
      );
      return modules;
    },
    moveToTopIfMatch(inputArray, valueToMove) {
      const index = inputArray.indexOf(valueToMove);
      if (index !== -1) {
        return [inputArray[index], ...inputArray.slice(0, index), ...inputArray.slice(index + 1)];
      }
      return inputArray;
    },
    onPredictionModelSelect(target) {
      const selectedModel = this.modules.find((m) => m.id === target.predictionModel);
      if (selectedModel) {
        target.parameters = Object();
        target.files = Object();
        target.osParameters = Object();
        target.osFiles = Object();
      }
    },
    onDialogOpen(target) {
      this.$refs.profileParametersInput.openDialog(
        target.predictionModel,
        target.parameters || {},
        target.files || {},
        target.osParameters || {},
        target.osFiles || {}
      );
    },
    confirmDialog(predModelId, params, files, osParams, osFiles) {
      const target = this.targets.find(t => t.predictionModel === predModelId);
      target.parameters = params;
      target.files = files;
      target.osParameters = osParams;
      target.osFiles = osFiles;
      this.$emit('updateProfileInput', target, 'objective_value' in osParams);
    }
  }
};
</script>

<style scoped>
.vue-slider-dot-tooltip-inner-bottom::after {
    bottom: 100%;
    padding-bottom: 20px;
    border-color: transparent;
    border-bottom-color: transparent;
    border-style: solid;
    border-width: 5px;
    border-bottom-color: inherit;
}
.slider-tooltip input {
    text-align: center;
    font-size: 12px;
    width: 30px;
    height: 20px;
    background-color: white;
}
/* Override disabled styles */
.vue-slider-disabled {
    opacity: 1;
    cursor: auto;
}
:deep(.vue-slider-process) {
    border-radius: 0;
}
:deep(.vue-slider-process:nth-last-child(3)),
:deep(.vue-slider-process:nth-last-child(4)) {
    border-radius: 0 15px 15px 0;
}
:deep(.vue-slider-process:first-child) {
    border-radius: 15px 0 0 15px;
}
:deep(.vue-slider-dot-handle-disabled) {
    display: none;
}
:deep(.vue-slider-mark-label) {
    margin-top: 0 !important;
}
.error-message {
    color: rgb(255, 82, 82);
    text-align: center;
}
.message {
    line-height: 12px;
    font-size: 12px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}
:deep(table) {
  table-layout: fixed;
  width: 100%;
}
</style>
