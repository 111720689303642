<template>
  <div>
    <v-row>
      <v-col :cols="colSize">
        <v-card class="mb-2">
          <v-card-title class="pb-0">Potency</v-card-title>
          <v-card-text class="pb-0">
            <ProfileSettingsTable
              ref="Potency"
              :targets="potencyTargets"
              :target-names="targetNames"
              :modules="modules"
              :used-module-ids="usedModuleIds"
              category="Potency"
              :scaling-types="scalingTypes"
              @deleteTarget="deleteTarget"
              @updateProfileInput="updateProfileInput"
            />
          </v-card-text>
          <v-card-actions class="pt-1">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="light-blue"
                  fab
                  dark
                  small
                  @click="addPotencyTarget"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>追加</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :cols="colSize">
        <v-card class="mb-4">
          <v-card-title class="pb-0">Toxicity</v-card-title>
          <v-card-text class="pb-0">
            <ProfileSettingsTable
              ref="Toxicity"
              :targets="toxicityTargets"
              :target-names="targetNames"
              :modules="modules"
              :used-module-ids="usedModuleIds"
              category="Toxicity"
              :scaling-types="scalingTypes"
              @deleteTarget="deleteTarget"
              @updateProfileInput="updateProfileInput"
            />
          </v-card-text>
          <v-card-actions class="pt-1">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="light-blue"
                  fab
                  dark
                  small
                  @click="addToxicityTarget"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>追加</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col :cols="colSize">
        <v-card class="mb-4">
          <v-card-title class="pb-0">ADME</v-card-title>
          <v-card-text class="pb-0">
            <ProfileSettingsTable
              ref="ADME"
              :targets="admeTargets"
              :target-names="targetNames"
              :modules="modules"
              :used-module-ids="usedModuleIds"
              category="ADME"
              :scaling-types="scalingTypes"
              @deleteTarget="deleteTarget"
              @updateProfileInput="updateProfileInput"
            />
          </v-card-text>
          <v-card-actions class="pt-1">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="light-blue"
                  fab
                  dark
                  small
                  @click="addADMETarget"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>追加</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col :cols="colSize">
        <v-card class="mb-4">
          <v-card-title class="pb-0">Property</v-card-title>
          <v-card-text class="pb-0">
            <ProfileSettingsTable
              ref="Property"
              :targets="propertyTargets"
              :target-names="targetNames"
              :modules="modules"
              :used-module-ids="usedModuleIds"
              category="Property"
              :scaling-types="scalingTypes"
              @deleteTarget="deleteTarget"
              @updateProfileInput="updateProfileInput"
            />
          </v-card-text>
          <v-card-actions class="pt-1">
            <v-tooltip top>
              <template #activator="{ on }">
                <v-btn
                  class="mx-2"
                  color="light-blue"
                  fab
                  dark
                  small
                  @click="addPropertyTarget"
                  v-on="on"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
              </template>
              <span>追加</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileSettingsTable
  from '@/components/design/Project/ProfileSettingsTable';

export default {
  name: 'ProfileSettings',
  components: {
    ProfileSettingsTable
  },
  props: {
    targets: {
      type: Array,
      default: () => []
    },
    targetNames: {
      type: Array,
      default: () => []
    },
    modules: {
      type: Array,
      default: () => []
    },
    usedModuleIds: {
      type: Array,
      default: () => []
    },
    scalingTypes: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
    };
  },
  computed: {
    potencyTargets: function() {
      return this.targets.filter(t => t.category === 'Potency');
    },
    toxicityTargets: function() {
      return this.targets.filter(t => t.category === 'Toxicity');
    },
    admeTargets: function() {
      return this.targets.filter(t => t.category === 'ADME');
    },
    propertyTargets: function() {
      return this.targets.filter(t => t.category === 'Property');
    },
    colSize: function() {
      return this.$vuetify.breakpoint.name === 'xl' ? '6' : '12';
    }
  },
  methods: {
    addPotencyTarget() {
      this.$emit('addTarget', 'Potency');
    },
    addToxicityTarget() {
      this.$emit('addTarget', 'Toxicity');
    },
    addADMETarget() {
      this.$emit('addTarget', 'ADME');
    },
    addPropertyTarget() {
      this.$emit('addTarget', 'Property');
    },
    deleteTarget(category, index) {
      this.$emit('deleteTarget', category, index);
    },
    validateAllRanges() {
      return [this.$refs.Potency.validateRanges(),
        this.$refs.ADME.validateRanges(),
        this.$refs.Toxicity.validateRanges(),
        this.$refs.Property.validateRanges()].every(bool => bool);
    },
    updateProfileInput(target, hasObjVal) {
      this.$emit('updateProfileInput', target, hasObjVal);
    }
  }
};
</script>
